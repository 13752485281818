import React from 'react';
import PropTypes from 'prop-types';
import { Row, FormGroup } from 'reactstrap';
import { Button } from 'components/Elements';
import { Formik, Form } from 'formik';
import TextField from 'components/TextField/TextField';
import palletHeightValidator from 'utils/validation/palletHeightValidator';

const HeightForm = ({ updatePallet, closeModal }) => {
  const addHeightClickHandler = async ({ height }) => {
    updatePallet({
      height
    }).then(() => {
      closeModal();
    });
  };
  return (
    <Formik
      initialValues={{ height: '' }}
      validationSchema={palletHeightValidator}
      onSubmit={addHeightClickHandler}
    >
      <Form autoComplete='off'>
        <FormGroup>
          <h6 className='mb-1'>Measure Height (Inches)</h6>
          <p className='mb-1 text-muted'>(Min: 15 in. - Max: 105 in.)</p>
          <TextField type='text' name='height' placeholder='Add height...' />
        </FormGroup>
        <Row>
          <Button color='primary' block className='w-50 m-auto' type='submit'>
            Submit
          </Button>
        </Row>
      </Form>
    </Formik>
  );
};

HeightForm.propTypes = {
  updatePallet: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default HeightForm;
