import React from 'react';
import { useSelector } from 'react-redux';
import ManageDockOutboundBtn from './components/ManageDockOutboundBtn';
import DoubleCheckSalesOrderReviewCheckListFooter from './components/DoubleCheckSalesOrderReviewCheckListFooter';
import PickSalesOrderItemDetailsFooter from './components/PickItemDetailsFooter/PickSalesOrderItemDetailsFooter';
import { selectIsFooterActive, selectFooterView } from './footerSlice';
import BinTransferItemDetailsBtn from './components/BinTransferItemDetailsBtn';
import DoorDetailsBtn from './components/DoorDetailsBtn';
import ShipmentDetailsBtn from './components/ShipmentDetailsBtn/ShipmentDetailsBtn';
import InspectItemDetailsBtn from './components/InspectItemDetailsBtn';
import InspectShipmentDetailsBtn from './components/InspectShipmentDetailsBtn';
import BinPutawayItemDetailsBtn from './components/BinPutawayItemDetailsBtn';
import PickSalesOrderDetailsBtn from './components/PickSalesOrderDetailsBtn';
import DoubleCheckSalesOrderDetailsBtn from './components/DoubleCheckSalesOrderDetailsBtn';
import DoubleCheckSalesOrderPalletDetailsBtn from './components/DoubleCheckSalesOrderPalletDetailsBtn';
import ManagePalletsPalletDetailsBtn from './components/ManagePalletsPalletDetailsBtn';
import PickLocationTransferDetailsBtn from './components/PickLocationTransferDetailsBtn';
import PickLocationTransferItemDetailsFooter from './components/PickItemDetailsFooter/PickLocationTransferItemDetailsFooter';
import WrapSalesOrderDetailsBtn from './components/WrapSalesOrderDetailsBtn';
import WrapSalesOrderPalletDetailsBtn from './components/WrapSalesOrderPalletDetailsBtn';
import ManageDockTruckDetailsBtn from './components/ManageDockTruckDetailsBtn';
import RevertCustomerOrderDetailsBtn from './components/RevertCustomerOrderDetailsBtn';
import RevertCustomerOrderBtn from './components/RevertCustomerOrderBtn';
import ManagePalletsHomeBtn from './components/ManagePalletsHomeBtn';
import CreatePalletBtn from './components/CreatePalletBtn';

const renderFooter = (view) => {
  switch (view) {
    case 'binTransferItemDetails':
      return <BinTransferItemDetailsBtn />;
    case 'doorDetails':
      return <DoorDetailsBtn />;
    case 'shipmentDetails':
      return <ShipmentDetailsBtn />;
    case 'inspectShipmentDetails':
      return <InspectShipmentDetailsBtn />;
    case 'inspectItemDetails':
      return <InspectItemDetailsBtn />;
    case 'binPutawayItemDetails':
      return <BinPutawayItemDetailsBtn />;
    case 'pickSalesOrderDetails':
      return <PickSalesOrderDetailsBtn />;
    case 'pickSalesOrderItemDetails':
      return <PickSalesOrderItemDetailsFooter />;
    case 'pickLocationTransferDetails':
      return <PickLocationTransferDetailsBtn />;
    case 'pickLocationTransferItemDetails':
      return <PickLocationTransferItemDetailsFooter />;
    case 'doubleCheckSalesOrderDetails':
      return <DoubleCheckSalesOrderDetailsBtn />;
    case 'doubleCheckSalesOrderReviewCheckList':
      return <DoubleCheckSalesOrderReviewCheckListFooter />;
    case 'doubleCheckSalesOrderPalletDetails':
      return <DoubleCheckSalesOrderPalletDetailsBtn />;
    case 'wrapSalesOrderDetails':
      return <WrapSalesOrderDetailsBtn />;
    case 'wrapSalesOrderPalletDetails':
      return <WrapSalesOrderPalletDetailsBtn />;
    case 'manageDockOutbound':
      return <ManageDockOutboundBtn />;
    case 'manageDockTruckDetails':
      return <ManageDockTruckDetailsBtn />;
    case 'revertCustomerOrderDetails':
      return <RevertCustomerOrderDetailsBtn />;
    case 'revertCustomerOrder':
      return <RevertCustomerOrderBtn />;
    case 'managePalletsHome':
    case 'binPutawayHome':
      return <ManagePalletsHomeBtn />;
    case 'managePalletsPalletDetails':
      return <ManagePalletsPalletDetailsBtn />;
    case 'createPallet':
      return <CreatePalletBtn />;
    default:
      return null;
  }
};

const Footer = () => {
  const isFooterActive = useSelector(selectIsFooterActive);
  const footerView = useSelector(selectFooterView);

  if (!isFooterActive || !footerView) {
    return null;
  }

  return (
    <div className='bg-transparent d-flex justify-content-center p-3 border-top border-light'>
      {renderFooter(footerView)}
    </div>
  );
};

export default Footer;
