/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  Label,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import cx from 'classnames';
import lockIcon from 'assets/icons/lock-fill.svg';
import unlockIcon from 'assets/icons/unlock-fill.svg';
import scannerIcon from 'assets/icons/upc-scan.svg';
// !todo: refactor image imports to use require so they don't have to load when state changes and updates src

const TextField = ({
  isDisabled = false,
  isLockedInput = false,
  isScannerInput = false,
  Tooltip = null,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [disabled, setDisabled] = useState(true);

  return (
    <>
      {label && (
        <Label className={cx('font-weight-semibold', { 'd-flex': !!Tooltip })}>
          {label} {Tooltip && <Tooltip />}
        </Label>
      )}
      <InputGroup>
        <Input
          disabled={
            isDisabled || ((isLockedInput || isScannerInput) && disabled)
          }
          invalid={!!meta.touched && !!meta.error}
          {...field}
          {...props}
          value={field.value || ''}
        />
        {!isDisabled && isLockedInput && (
          <InputGroupAddon addonType='append'>
            <Button color='gray' onClick={() => setDisabled((prev) => !prev)}>
              <img
                src={disabled ? lockIcon : unlockIcon}
                alt='enable-manual-input'
                width='20'
                height='20'
                key='enable-manual-input'
              />
            </Button>
          </InputGroupAddon>
        )}
        {!isDisabled && isScannerInput && (
          <InputGroupAddon addonType='append'>
            <Button color='gray' disabled>
              <img
                src={scannerIcon}
                alt='enable-manual-input'
                width='20'
                height='20'
                key='enable-manual-input'
              />
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      {meta.touched && meta.error && (
        <FormFeedback className='d-block'>{meta.error}</FormFeedback>
      )}
    </>
  );
};

TextField.propTypes = {
  isDisabled: PropTypes.bool,
  isLockedInput: PropTypes.bool,
  isScannerInput: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  Tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default TextField;
