import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import {
  createStore,
  setAPI,
  setPNETAPI,
  setIMSAPI,
  setCARRIERAPI
} from 'store';
import axios from 'axios';
import inMemoryJWT from 'utils/auth/inMemoryJWTManager';
import { Provider } from 'react-redux';
import authProvider from 'utils/auth/authProvider';
import { Admin } from '@alpinehomeair/alpine-admin';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import App from './components/App/App';

const rollbarConfig = {
  accessToken: 'b581ede765f04d4482fb2c0fe3debac7',
  enabled:
    process.env.RollbarEnv === 'production' || process.env.RollbarEnv === 'qa',
  itemsPerMinute: 60,
  environment: process.env.RollbarEnv,
  code_version: process.env.VERSION_INFO || 'development'
};

const api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL
});

const pnetAPI = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_PARTNERNETAPI_URL
});

const imsAPI = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_IMSAPI_URL
});

const carrierAPI = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_CARRIERAPI_URL
});

const checkErrorResponse = (config) => {
  if (config.data.error) {
    return Promise.reject(config.data);
  }
  return config;
};

api.interceptors.response.use(checkErrorResponse, (error) =>
  Promise.reject(error)
);

pnetAPI.interceptors.response.use(checkErrorResponse, (error) =>
  Promise.reject(error)
);

imsAPI.interceptors.response.use(checkErrorResponse, (error) =>
  Promise.reject(error)
);

carrierAPI.interceptors.response.use(checkErrorResponse, (error) =>
  Promise.reject(error)
);

setAPI(api);
setIMSAPI(imsAPI);
setPNETAPI(pnetAPI);
setCARRIERAPI(carrierAPI);

const store = createStore();

// eslint-disable-next-line arrow-body-style
const isHandlerEnabled = (config = {}) => {
  return !('handlerEnabled' in config) || config.handlerEnabled;
};

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const jwtToken = inMemoryJWT.getToken();
    if (jwtToken && jwtToken !== '') {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer: ${jwtToken}`;
    }
  }

  return request;
};

api.interceptors.request.use((request) => requestHandler(request));
imsAPI.interceptors.request.use((request) => requestHandler(request));
pnetAPI.interceptors.request.use((request) => requestHandler(request));
carrierAPI.interceptors.request.use((request) => requestHandler(request));

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Admin authProvider={authProvider}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </Admin>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
