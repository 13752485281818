/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import getErrorMessage from 'utils/getErrorMessage';
import { setLoading } from 'components/Loader/loaderSlice';

export const manageShipmentsSlice = createSlice({
  name: 'manageShipments',
  initialState: {
    shipments: [],
    searchQuery: ''
  },
  reducers: {
    getAllShipmentsSuccess: (state, { payload }) => {
      state.shipments = payload;
    },
    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload;
    }
  }
});

export const { getAllShipmentsSuccess, setSearchQuery } =
  manageShipmentsSlice.actions;
export const fetchAllShipments =
  () =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/shipment/`);
      dispatch(getAllShipmentsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e), { autoClose: 2000 });
      dispatch(setLoading(false));
    }
  };

export const selectSearchQuery = (state) => state.manageShipments.searchQuery;
export const selectShipments = (state) => state.manageShipments.shipments;

export default manageShipmentsSlice.reducer;
