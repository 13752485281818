import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CartonCountForm from './components/CartonCountForm';
import DimensionsForm from './components/DimensionsForm';
import HeightForm from './components/HeightForm';

const SalesOrderPalletUpdateModal = ({
  palletBarcode,
  steps,
  updatePallet,
  modalCloseSuccess
}) => {
  const [modalStep, setModalStep] = useState(1);

  const nextStep = () => {
    setModalStep((prev) => prev + 1);
  };

  const closeModal = () => {
    if (modalCloseSuccess) {
      modalCloseSuccess();
    }
  };

  const renderSteps = () => {
    let mappedSteps = [];

    steps.forEach((step) => {
      if (step === 'cartonCount') {
        mappedSteps = [
          ...mappedSteps,
          {
            component: (
              <CartonCountForm
                updatePallet={updatePallet}
                nextStep={nextStep}
              />
            )
          }
        ];
      }
      if (step === 'dimensions') {
        mappedSteps = [
          ...mappedSteps,
          {
            component: (
              <DimensionsForm updatePallet={updatePallet} nextStep={nextStep} />
            )
          }
        ];
      }
      if (step === 'height') {
        mappedSteps = [
          ...mappedSteps,
          {
            component: (
              <HeightForm updatePallet={updatePallet} closeModal={closeModal} />
            )
          }
        ];
      }

      return null;
    });
    return mappedSteps[modalStep - 1].component;
  };

  return (
    <Modal isOpen={true} toggle={() => setModalStep(1)} centered size='sm'>
      <ModalHeader className='p-2 bg-warning justify-content-center'>
        Pallet {palletBarcode}
      </ModalHeader>
      <ModalBody className='py-2'>{renderSteps()}</ModalBody>
    </Modal>
  );
};

SalesOrderPalletUpdateModal.propTypes = {
  palletBarcode: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  updatePallet: PropTypes.func.isRequired,
  modalCloseSuccess: PropTypes.func
};

export default SalesOrderPalletUpdateModal;
