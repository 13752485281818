import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'components/Elements';

const SalesOrderProcessCompletedModal = ({
  isOpen,
  toggle,
  currentProcess,
  nextProcess,
  redirect,
  link,
  linkText
}) => {
  const history = useHistory();

  const handleLinkRedirect = () => {
    history.push(link);
  };

  const handleRedirect = () => {
    history.replace(redirect);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='text-center'>
        <p className='lead'>
          <b>
            Successfully completed:
            <br />
            {currentProcess}
          </b>
        </p>
        {link && (
          <p className='d-block mb-3 text-center'>
            <Button onClick={handleLinkRedirect}>{linkText}</Button>
          </p>
        )}
        <Button color='success' onClick={handleRedirect}>
          Next: {nextProcess}
        </Button>
      </ModalBody>
    </Modal>
  );
};

SalesOrderProcessCompletedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  currentProcess: PropTypes.string.isRequired,
  nextProcess: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: (props, propName, componentName) => {
    if (props.link && !props.linkText) {
      return new Error(
        `Prop \`${propName}\` is required in \`${componentName}\` when \`link\` is provided.`
      );
    }
    return null;
  }
};

export default SalesOrderProcessCompletedModal;
