import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import {
  breakPallet,
  selectPallet
} from 'pages/ManagePallets/managePalletsSlice';

const ManagePalletsPalletDetailsBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pallet = useSelector(selectPallet);
  const [modal, setModal] = useState(false);

  const handlePutawayPallet = () => {
    history.replace(
      `/receiving/bin-putaway/pallet-details/${pallet.id}/confirmation`
    );
  };

  const handleTransferPallet = () => {
    history.replace(
      `/bin-transfer/${pallet.binName}/pallet-details/${pallet.id}/confirmation`
    );
  };

  const initiateBreakPallet = async () => {
    const res = await dispatch(breakPallet(pallet.id, pallet.barcode));
    if (res) {
      history.replace(`/manage-pallets`);
    }
  };

  const toggle = () => setModal(!modal);

  return (
    <>
      <Row className='w-100'>
        <Col
          className={cx('pl-0 pr-0', {
            'pr-2': pallet.items.length
          })}
        >
          <Button color='warning' block onClick={toggle}>
            Break Pallet
          </Button>
        </Col>
        {!!pallet.items.length && pallet.isOpen && (
          <Col className='pr-0 pl-2'>
            <Button color='primary' block onClick={handlePutawayPallet}>
              Putaway Pallet
            </Button>
          </Col>
        )}
        {!!pallet.items.length && !pallet.isOpen && (
          <Col className='pr-0 pl-2'>
            <Button color='primary' block onClick={handleTransferPallet}>
              Transfer Pallet
            </Button>
          </Col>
        )}
      </Row>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>{`Are you sure you want break down Pallet ${pallet.barcode}?`}</h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button color='primary' outline block onClick={toggle}>
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button color='primary' block onClick={initiateBreakPallet}>
                Break Pallet
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ManagePalletsPalletDetailsBtn;
