import React from 'react';
import { Alert as ReactstrapAlert } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  selectIsAlertActive,
  selectAlertView,
  selectAlertColor
} from './alertSlice';
import InspectItemDetailsAlert from './components/InspectItemDetailsAlert';
import ShipmentDetailsAlert from './components/ShipmentDetailsAlert';
import PickSalesOrderItemDetailsAlert from './components/PickSalesOrderItemDetailsAlert';
import PickLocationTransferItemDetailsAlert from './components/PickLocationTransferItemDetailsAlert';

const renderAlert = (view) => {
  switch (view) {
    case 'inspectItemDetails':
      return <InspectItemDetailsAlert />;
    case 'shipmentDetails':
      return <ShipmentDetailsAlert />;
    case 'pickSalesOrderItemDetails':
      return <PickSalesOrderItemDetailsAlert />;
    case 'pickLocationTransferItemDetails':
      return <PickLocationTransferItemDetailsAlert />;
    default:
      return null;
  }
};

const Alert = () => {
  const isAlertActive = useSelector(selectIsAlertActive);
  const alertView = useSelector(selectAlertView);
  const alertColor = useSelector(selectAlertColor);

  if (!isAlertActive || !alertView) {
    return null;
  }
  return (
    <ReactstrapAlert
      color={alertColor}
      className='d-flex justify-content-center rounded-0 m-0 p-1'
    >
      {renderAlert(alertView)}
    </ReactstrapAlert>
  );
};

export default Alert;
