/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    isActive: false,
    view: null,
    color: 'danger'
  },
  reducers: {
    setIsAlertActive: (state, { payload }) => {
      state.isActive = payload;
    },
    setAlertView: (state, { payload }) => {
      state.view = payload;
    },
    setAlertColor: (state, { payload }) => {
      state.color = payload;
    }
  }
});

export const { setIsAlertActive, setAlertView, setAlertColor } =
  alertSlice.actions;

export const selectIsAlertActive = (state) => state.alert.isActive;
export const selectAlertView = (state) => state.alert.view;
export const selectAlertColor = (state) => state.alert.color;

export default alertSlice.reducer;
