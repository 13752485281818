import React from 'react';
import { Card as CardComponent } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCardComponent = styled(CardComponent)`
  border-radius: ${(props) => props.borderRadius || '0.25rem'};
  @media (max-width: 768px) {
    padding: 0.5rem 0.6rem;
    font-size: 15px;
  }
`;

const Card = ({ children, body, ...rest }) => {
  return (
    <StyledCardComponent body {...rest}>
      {children}
    </StyledCardComponent>
  );
};

Card.propTypes = {
  body: PropTypes.bool,
  color: PropTypes.string,
  inverse: PropTypes.bool,
  outline: PropTypes.bool,
  style: PropTypes.object,
  borderRadius: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Card;
