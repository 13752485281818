import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, FormGroup, Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { createPallet } from 'pages/ManagePallets/managePalletsSlice';
import TextField from 'components/TextField/TextField';
import Scanner from 'components/Scanner/Scanner';
import ScanCreatePalletTooltip from 'components/Tooltip/components/ScanCreatePalletTooltip';

const ManagePalletsHomeBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  const handleSubmit = async (values) => {
    const res = await dispatch(createPallet(values.palletBarcode));

    if (res) {
      history.replace(`/manage-pallets/${res.id}`);
    }
  };

  return (
    <>
      <Button color='primary' block onClick={toggle}>
        Create Pallet
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={{ palletBarcode: '' }}
            onSubmit={handleSubmit}
          >
            <Form autoComplete='off'>
              <FormGroup>
                <Scanner
                  fieldName='palletBarcode'
                  unmountToPrevious
                  shouldMount={isOpen}
                >
                  <TextField
                    isScannerInput
                    type='text'
                    name='palletBarcode'
                    label='Scan Pallet'
                    placeholder='Scan to create a pallet'
                    Tooltip={ScanCreatePalletTooltip}
                  />
                </Scanner>
              </FormGroup>
            </Form>
          </Formik>
          <Row className='mb-1'>
            <Col>
              <Button color='danger' block onClick={toggle}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ManagePalletsHomeBtn;
