import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { selectShipment } from 'components/ShipmentDetails/shipmentDetailsSlice';

const GoToInspection = () => {
  const history = useHistory();
  const shipment = useSelector(selectShipment);

  const handleClick = async () => {
    history.replace(`/receiving/inspect-shipment/${shipment.id}`);
  };

  return (
    <>
      <Button color='primary' block onClick={handleClick}>
        Go to Inspection
      </Button>
    </>
  );
};

export default GoToInspection;
