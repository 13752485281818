import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, FormGroup } from 'reactstrap';
import { Button } from 'components/Elements';
import TextField from 'components/TextField/TextField';
import { Formik, Form } from 'formik';
import cartonCountValidator from 'utils/validation/cartonCountValidator';
import { selectSalesOrderPallet } from 'pages/DoubleCheck/DoubleCheckSalesOrder/doubleCheckSalesOrderSlice';

const CartonCountForm = ({ nextStep, updatePallet }) => {
  const salesOrderPallet = useSelector(selectSalesOrderPallet);

  const addPieceCountPalletClickHandler = async ({ cartonCount }) => {
    updatePallet({
      cartonCount
    }).then(() => {
      nextStep();
    });
  };

  return (
    <Formik
      initialValues={{ cartonCount: '' }}
      validationSchema={cartonCountValidator(
        salesOrderPallet.sublineItems.length
      )}
      onSubmit={addPieceCountPalletClickHandler}
    >
      <Form autoComplete='off'>
        <FormGroup>
          <TextField
            type='text'
            name='cartonCount'
            label='Carton Count'
            placeholder='Add carton count...'
          />
        </FormGroup>
        <Row>
          <Button color='primary' block className='w-50 m-auto' type='submit'>
            Next
          </Button>
        </Row>
      </Form>
    </Formik>
  );
};

CartonCountForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  updatePallet: PropTypes.func.isRequired
};

export default CartonCountForm;
