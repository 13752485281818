import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonComponent } from 'reactstrap';
import styled from 'styled-components';
import cx from 'classnames';

const StyledButton = styled(ButtonComponent)`
  &.btn-link {
    padding: 0;
    background: none;
    border: none;
    margin-top: -4px;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      color: inherit;
    }
  }
`;

const Button = ({
  color = 'primary',
  outline = false,
  active = false,
  close = false,
  link = false,
  disabled = false,
  children,
  className,
  ...rest
}) => {
  return (
    <StyledButton
      color={color}
      outline={outline}
      active={active}
      close={close}
      disabled={disabled}
      link={link.toString()}
      className={cx(className, {
        'btn-link': link
      })}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  outline: PropTypes.bool,
  size: PropTypes.string,
  block: PropTypes.bool,
  active: PropTypes.bool,
  close: PropTypes.bool,
  link: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Button;
