import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const BinTransferItemDetailsBtn = () => {
  const history = useHistory();
  const match = useRouteMatch('/bin-transfer/:binCode/item-details/:itemSKU');

  return (
    <Button
      color='primary'
      block
      onClick={() => {
        history.replace(
          `/bin-transfer/${match?.params?.binCode}/item-details/${match?.params?.itemSKU}/confirmation`
        );
      }}
    >
      Confirm Qty
    </Button>
  );
};

export default BinTransferItemDetailsBtn;
