import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'components/Elements';
import { useHistory } from 'react-router-dom';
import {
  selectSalesOrder,
  selectCanAddPallet,
  selectAreAllPalletsClosed,
  selectAllPalletsHaveItems
} from 'pages/DoubleCheck/DoubleCheckSalesOrder/doubleCheckSalesOrderSlice';

const DoubleCheckSalesOrderDetailsBtn = () => {
  const history = useHistory();
  const salesOrder = useSelector(selectSalesOrder);
  const canAddPallet = useSelector(selectCanAddPallet);
  const areAllPalletsClosed = useSelector(selectAreAllPalletsClosed);
  const allPalletsHaveItems = useSelector(selectAllPalletsHaveItems);

  const handleFinalizeDoubleCheck = () => {
    history.replace(`/double-check/${salesOrder.orderNumber}/review-checklist`);
  };
  return (
    <>
      {salesOrder.canWrapItems &&
        !canAddPallet &&
        allPalletsHaveItems &&
        areAllPalletsClosed && (
          <Button color='primary' block onClick={handleFinalizeDoubleCheck}>
            Next: Review Checklist
          </Button>
        )}
    </>
  );
};

export default DoubleCheckSalesOrderDetailsBtn;
