/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    notifications: {
      pickedSalesOrderCount: 0,
      doubleCheckedOrderCount: 0,
      wrapOrderCount: 0,
      manageDockPalletsCount: 0
    }
  },
  reducers: {
    getPickingNotificationSuccess: (state, { payload }) => {
      state.notifications.pickedSalesOrderCount = payload.count;
    },
    getDoubleCheckingNotificationSuccess: (state, { payload }) => {
      state.notifications.doubleCheckedOrderCount = payload.count;
    },
    getWrapNotificationSuccess: (state, { payload }) => {
      state.notifications.wrapOrderCount = payload.count;
    },
    getManageDockNotificationSuccess: (state, { payload }) => {
      state.notifications.manageDockPalletsCount = payload.count;
    }
  }
});

export const {
  getPickingNotificationSuccess,
  getDoubleCheckingNotificationSuccess,
  getWrapNotificationSuccess,
  getManageDockNotificationSuccess
} = homeSlice.actions;
export const fetchPickingSalesOrderNotificationsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/picking-sales-order/location/${locationId}/notifications`
      );
      dispatch(getPickingNotificationSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchDoubleCheckingSalesOrderNotificationsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/double-checking-sales-order/location/${locationId}/notifications`
      );
      dispatch(getDoubleCheckingNotificationSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchWrapSalesOrderNotificationsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/wrapping-sales-order/location/${locationId}/notifications`
      );
      dispatch(getWrapNotificationSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchManageDockNotificationsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/manage-dock/location/${locationId}/notifications`
      );
      dispatch(getManageDockNotificationSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const selectNotifications = (state) => state.home.notifications;

export default homeSlice.reducer;
