/* eslint-disable no-param-reassign */
const getActivePickersFromSublineItems = (sublineItems) => {
  return sublineItems.reduce(
    (acc, { pickedBy, doubleCheckedBy, doubleCheckedByUserId }) => {
      if (pickedBy && !doubleCheckedBy && !doubleCheckedByUserId) {
        acc.push(pickedBy);
      }

      return acc;
    },
    []
  );
};

const getActivePickersFromInventoryItems = (inventoryItems) => {
  return inventoryItems.reduce((acc, { sublineItems }) => {
    acc = [...acc, ...getActivePickersFromSublineItems(sublineItems)];

    return acc;
  }, []);
};

const getActivePickersFromSalesOrder = (salesOrder) => {
  return [
    ...new Set([
      ...getActivePickersFromInventoryItems(salesOrder.equipmentPickList),
      ...getActivePickersFromInventoryItems(salesOrder.smallPackPickList)
    ])
  ];
};

export default getActivePickersFromSalesOrder;
