import React from 'react';
import { Route } from 'react-router-dom';
import { Authenticated } from '@alpinehomeair/alpine-admin';
import AuthWait from 'components/Authentication/AuthWait/AuthWait';

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => {
        if (route.authenticated) {
          return (
            <Authenticated>
              <AuthWait>
                <route.component {...props} routes={route.routes} />
              </AuthWait>
            </Authenticated>
          );
        }
        return <route.component {...props} routes={route.routes} />;
      }}
    />
  );
};

export default RouteWithSubRoutes;
