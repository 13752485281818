/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';
import getActivePickersFromSalesOrder from 'utils/getActivePickersFromSalesOrder';

const initialState = {
  salesOrder: {
    canFulfillItems: null,
    canDoubleCheckItems: null,
    orderNumber: '',
    customerName: '',
    pickStatus: '',
    pickProgress: '',
    equipmentPickList: [],
    smallPackPickList: []
  },
  salesOrderItem: {
    binName: '',
    customerName: '',
    imageUrl: '',
    inventoryItemId: null,
    isSerialized: null,
    isSmallPack: null,
    manufacturer: '',
    model: '',
    orderNumber: '',
    orderPickProgress: '',
    pickListPartNumber: '',
    orderPickStatus: '',
    pickProgress: '0/0',
    pickQuantity: 0,
    pickStatus: '',
    section: '',
    sku: '',
    sublineItems: [],
    activePickers: [],
    alternativeBins: [],
    hasAlternativeBins: null
  },
  targetPickBin: {
    bin: '',
    itemQuantity: null,
    locationId: null
  },
  nextPick: {
    bin: '',
    sku: ''
  }
};

export const pickSalesOrderSlice = createSlice({
  name: 'pickSalesOrder',
  initialState,
  reducers: {
    getSalesOrderPickDetailsSuccess: (state, { payload }) => {
      state.salesOrder = payload;
    },
    getSalesOrderPickItemDetailsSuccess: (state, { payload }) => {
      state.salesOrderItem = payload;
    },
    getSalesOrderTargetPickBinSuccess: (state, { payload }) => {
      state.targetPickBin = payload;
    },
    getSalesOrderNextPickSuccess: (state, { payload }) => {
      state.nextPick = payload;
    },
    clearSalesOrderPickItemDetails: (state) => {
      const {
        salesOrderItem: { pickProgress, pickQuantity, pickStatus, ...rest }
      } = initialState;
      state.salesOrderItem = { ...state.salesOrderItem, ...rest };
    }
  }
});

export const {
  getSalesOrderPickDetailsSuccess,
  getSalesOrderPickItemDetailsSuccess,
  getSalesOrderTargetPickBinSuccess,
  getSalesOrderNextPickSuccess,
  clearSalesOrderPickItemDetails
} = pickSalesOrderSlice.actions;
export const validatePickSalesOrder =
  (salesOrderBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.get(
        `/picking-sales-order/${salesOrderBarcode}/location/${locationId}/validate-pick`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSalesOrderPickDetails =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/picking-sales-order/${salesOrderNumber}/location/${locationId}/pick-list`
      );
      dispatch(getSalesOrderPickDetailsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchSalesOrderPickItemDetails =
  (salesOrderNumber, binName, itemSKU) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/picking-sales-order/${salesOrderNumber}/location/${locationId}/pick-bin/${binName}/pick-item/${itemSKU}`
      );
      dispatch(getSalesOrderPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchSalesOrderTargetPickBinDetails =
  (binName, itemSKU) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const { pickStatus } = getState().pickSalesOrder.salesOrderItem;
      if (pickStatus === 'Complete') {
        toast.error('Picking is already complete for this item');
        return false;
      }
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/${binName}/warehouse/${warehouseId}/item/${itemSKU}/target-bin`
      );
      dispatch(getSalesOrderTargetPickBinSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSalesOrderNextPick =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/picking-sales-order/${salesOrderNumber}/location/${locationId}/next-pick`
      );
      dispatch(getSalesOrderNextPickSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const pickSublineItemBySerial =
  (serialNumber, binName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { orderNumber, inventoryItemId } =
        getState().pickSalesOrder.salesOrderItem;
      const res = await imsAPI.put(
        `/picking-sales-order/${orderNumber}/location/${locationId}/pick-item/serialized`,
        {
          binName,
          serialNumber,
          inventoryItemId
        }
      );
      dispatch(getSalesOrderPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `Serial ${serialNumber} has been picked for order ${orderNumber}`,
        {
          autoClose: 2000
        }
      );
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const unpickSublineItem =
  (sublineItemId, binName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { orderNumber } = getState().pickSalesOrder.salesOrderItem;
      const res = await imsAPI.put(
        `/picking-sales-order/${orderNumber}/location/${locationId}/unpick-item`,
        {
          binName,
          sublineItemId
        }
      );
      dispatch(getSalesOrderPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      toast.warning(`Item successfully unpicked for order ${orderNumber}`, {
        autoClose: 2000
      });
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const pickSublineItemByQty =
  (binName, itemSKU, quantity) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { orderNumber, inventoryItemId, model, manufacturer } =
        getState().pickSalesOrder.salesOrderItem;
      const res = await imsAPI.put(
        `/picking-sales-order/${orderNumber}/location/${locationId}/pick-item/unserialized`,
        {
          binName,
          inventoryItemId,
          itemSKU,
          quantity
        }
      );
      dispatch(getSalesOrderPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `${quantity} ${manufacturer} ${model} has been picked for order ${orderNumber}`,
        {
          autoClose: 2000
        }
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fulfillPickedItems =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const { orderNumber } = getState().pickSalesOrder.salesOrder;
      const res = await imsAPI.post(
        `/picking-sales-order/${orderNumber}/warehouse/${warehouseId}/fulfill-picked-items`
      );
      if (res.data) {
        dispatch(getSalesOrderPickDetailsSuccess(res.data));
        toast.success(`Fulfillment created for Sales Order ${orderNumber}`, {
          autoClose: 2000
        });
      }
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const completeSalesOrderPick =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.get(
        `/picking-sales-order/${salesOrderNumber}/location/${locationId}/complete-pick`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectSalesOrder = (state) => state.pickSalesOrder.salesOrder;
export const selectSalesOrderItem = (state) =>
  state.pickSalesOrder.salesOrderItem;
export const selectSublineItems = (state) =>
  state.pickSalesOrder.salesOrderItem.sublineItems;
export const selectPickedSublineItems = (state) =>
  state.pickSalesOrder.salesOrderItem.sublineItems
    .filter((sublineItem) => sublineItem.pickedBy)
    .sort((a, b) => (!a.fulfillDate && b.fulfillDate ? -1 : 1));
export const selectQuantityLeftToPick = (state) =>
  state.pickSalesOrder.salesOrderItem.sublineItems.filter(
    (sublineItem) => !sublineItem.pickedBy
  ).length;
export const selectTargetPickBin = (state) =>
  state.pickSalesOrder.targetPickBin;
export const selectNextPick = (state) => state.pickSalesOrder.nextPick;
export const selectSalesOrderActivePickers = (state) =>
  getActivePickersFromSalesOrder(state.pickSalesOrder.salesOrder);
export const selectOtherActivePickers = (state) =>
  state.pickSalesOrder.salesOrderItem.activePickers.filter(
    (name) => name !== state.authentication.user.name
  );
export const selectIsSalesOrderSmallPackOnly = (state) =>
  state.pickSalesOrder.salesOrder.equipmentPickList.length === 0 &&
  state.pickSalesOrder.salesOrder.smallPackPickList.length;

export default pickSalesOrderSlice.reducer;
