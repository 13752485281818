/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

const initialState = {
  bin: {
    sectionName: '',
    binName: '',
    binEntities: {
      pallets: [],
      items: []
    }
  },
  binTransferItem: {
    sectionName: '',
    binName: '',
    model: '',
    manufacturer: '',
    imageUrl: '',
    isSerialized: false,
    inventoryItemId: null,
    sublineItems: [],
    quantity: null
  },
  unSerializedSelectedQuantity: 0,
  selectedSerialNumbers: [],
  recommendedBins: []
};

export const binTransferSlice = createSlice({
  name: 'binTransfer',
  initialState,
  reducers: {
    getBinSuccess: (state, { payload }) => {
      state.bin = payload;
    },
    getBinTransferItemSuccess: (state, { payload }) => {
      state.binTransferItem = payload;
    },
    getRecommendedBinsSuccess: (state, { payload }) => {
      state.recommendedBins = payload;
    },
    setUnSerializedSelectedQuantity: (state, { payload }) => {
      state.unSerializedSelectedQuantity = payload;
    },
    selectSerialNumberSuccess: (state, { payload }) => {
      state.selectedSerialNumbers = [...state.selectedSerialNumbers, payload];
    },
    deselectSerialNumberSuccess: (state, { payload }) => {
      state.selectedSerialNumbers = state.selectedSerialNumbers.filter(
        (serialNumber) => serialNumber !== payload
      );
    },

    addSelectedSublineItem: (state, { payload }) => {
      state.selectedSublineItems.push(payload);
    },
    removeSelectedSublineItem: (state, { payload }) => {
      state.selectedSublineItems = state.selectedSublineItems.filter(
        (sublineItem) => sublineItem.sublineItemId !== payload
      );
    },
    setSelectedSublineItemsByQty: (state, { payload: { sku, quantity } }) => {
      const item = state.bin.items.find(
        (inventoryItem) => inventoryItem.sku === sku
      );
      const selectedItems = item.sublineItems.slice(0, quantity);
      state.selectedSublineItems = selectedItems;
    },
    resetSelectedSublineItems: (state) => {
      state.selectedSublineItems = [];
    },
    resetBinTransfer: (state) => {
      state.bin = initialState.bin;
      state.binTransferItem = initialState.binTransferItem;
      state.unSerializedSelectedQuantity =
        initialState.unSerializedSelectedQuantity;
      state.selectedSerialNumbers = initialState.selectedSerialNumbers;
      state.recommendedBins = initialState.recommendedBins;
    }
  }
});

export const {
  getBinSuccess,
  getRecommendedBinsSuccess,
  addSelectedSublineItem,
  removeSelectedSublineItem,
  setSelectedSublineItemsByQty,
  resetSelectedSublineItems,
  resetBinTransfer,
  getBinTransferItemSuccess,
  setUnSerializedSelectedQuantity,
  selectSerialNumberSuccess,
  deselectSerialNumberSuccess
} = binTransferSlice.actions;
export const checkBinExistsAtLocation =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      await imsAPI.get(`/bin/${binBarcode}/warehouse/${warehouseId}/validate`);
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };
export const fetchBinByLocationIdAndBinName =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/${binBarcode}/warehouse/${warehouseId}`
      );
      dispatch(getBinSuccess(res.data));
      dispatch(setLoading(false));
      return { type: 'Success' };
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return { type: 'Failure' };
    }
  };
export const fetchBinTransferEntity =
  (binBarcode, entityBarcode) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));

      const res = await imsAPI.get(
        `/bin/${binBarcode}/entity/${encodeURIComponent(entityBarcode)}`
      );

      dispatch(setLoading(false));

      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));

      dispatch(setLoading(false));

      return false;
    }
  };
export const fetchBinItemBySKU =
  (binBarcode, itemSKU) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/${binBarcode}/warehouse/${warehouseId}/item/${itemSKU}`
      );
      dispatch(getBinTransferItemSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const fetchPalletRecommendedBins =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const { items } = getState().managePallets.pallet;
      const sublineItemIdList = items
        .reduce((acc, item) => {
          acc = [
            ...acc,
            item.sublineItems.map((sublineItem) => sublineItem.sublineItemId)
          ];
          return acc;
        }, [])
        .join(',');
      const res = await imsAPI.post(`/bin/recommended/`, {
        sublineItemIdList
      });
      dispatch(getRecommendedBinsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };
export const fetchItemRecommendedBins =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const { sublineItems } = getState().binTransfer.binTransferItem;
      const sublineItemIdList = sublineItems
        .map((sublineItem) => sublineItem.sublineItemId)
        .join(', ');
      const res = await imsAPI.post(`/bin/recommended/`, {
        sublineItemIdList
      });
      dispatch(getRecommendedBinsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };
export const transferSerializedItem =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const {
        selectedSerialNumbers,
        binTransferItem: { model, manufacturer, inventoryItemId, sublineItems }
      } = getState().binTransfer;
      const formattedSublineItems = sublineItems.reduce(
        (acc, { serialNumber, sublineItemId }) => {
          if (selectedSerialNumbers.includes(serialNumber)) {
            acc.push({ id: sublineItemId });
          }
          return acc;
        },
        []
      );

      await imsAPI.post('/bin/items/transfer', {
        warehouseId,
        destinationBinBarcode: binBarcode,
        inventoryItemId,
        sublineItems: formattedSublineItems
      });
      dispatch(setLoading(false));
      toast.success(
        `Bin transfer of ${selectedSerialNumbers.length} ${manufacturer} ${model} to ${binBarcode} has been Successful`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const transferUnserializedItem =
  (originBinBarcode, destinationBinBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const {
        unSerializedSelectedQuantity,
        binTransferItem: { inventoryItemId, model, manufacturer, sublineItems }
      } = getState().binTransfer;
      const formattedSublineItems = sublineItems
        .slice(0, unSerializedSelectedQuantity)
        .map(({ sublineItemId }) => ({ id: sublineItemId }));

      await imsAPI.post(`/bin/items/transfer`, {
        warehouseId,
        inventoryItemId,
        originBinBarcode,
        destinationBinBarcode,
        sublineItems: formattedSublineItems
      });
      dispatch(setLoading(false));
      toast.success(
        `Bin transfer of ${unSerializedSelectedQuantity} ${manufacturer} ${model} to ${destinationBinBarcode} has been Successful`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const transferPallet =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const { barcode, items } = getState().managePallets.pallet;
      const distinctItems = items.map(({ inventoryItemId, sublineItems }) => {
        return {
          warehouseId,
          destinationBinBarcode: binBarcode,
          inventoryItemId,
          sublineItems: sublineItems.map(({ sublineItemId }) => ({
            id: sublineItemId
          }))
        };
      });
      await imsAPI.post(`/bin/pallet/transfer`, {
        warehouseId,
        items: distinctItems
      });
      dispatch(setLoading(false));
      toast.success(
        `Bin transfer of Pallet ${barcode} to ${binBarcode} has been Successful`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const selectSerialNumber =
  (serialNumber, binCode, itemSKU) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const {
        selectedSerialNumbers,
        binTransferItem: { sublineItems, inventoryItemId }
      } = getState().binTransfer;
      let isAvailable = sublineItems.some(
        (sublineItem) => sublineItem.serialNumber === serialNumber
      );
      const isAlreadySelected = selectedSerialNumbers.some(
        (selectedSerialNumber) => selectedSerialNumber === serialNumber
      );
      const [sublineToAssignSerial] = sublineItems.filter(
        (sublineItem) => sublineItem.serialNumber === ''
      );

      if (!isAvailable && sublineToAssignSerial) {
        const warehouseId = getState().authentication.location.id;
        dispatch(setLoading(true));
        const res = await imsAPI.put(`/subline-items/serial-number`, {
          warehouseId,
          binCode,
          itemSKU,
          inventoryItemId,
          sublineItemId: sublineToAssignSerial.sublineItemId,
          serialNumber
        });

        dispatch(getBinTransferItemSuccess(res.data));
        dispatch(setLoading(false));
        isAvailable = true;
      }

      if (!isAvailable) {
        toast.error(
          `Serial ${serialNumber} is not associated with this item or not in bin`
        );
      }
      if (isAlreadySelected) {
        toast.error(
          `Serial ${serialNumber} has already been selected for bin transfer`
        );
      }
      if (isAvailable && !isAlreadySelected) {
        dispatch(selectSerialNumberSuccess(serialNumber));
        toast.success(
          `Serial ${serialNumber} has been selected for bin transfer`,
          {
            autoClose: 2000
          }
        );
      }

      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const deselectSerialNumber = (serialNumber) => (dispatch) => {
  dispatch(deselectSerialNumberSuccess(serialNumber));
  toast.warning(`Serial ${serialNumber} has been deselected for bin transfer`, {
    autoClose: 2000
  });
};

export const validateBinItem =
  (scannedValue, binBarcode) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(
        `/bin/${binBarcode}/item/${scannedValue}/validate`
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectBin = (state) => state.binTransfer.bin;
export const selectBinTransferItem = (state) =>
  state.binTransfer.binTransferItem;
export const selectUnSerializedSelectedQuantity = (state) =>
  state.binTransfer.unSerializedSelectedQuantity;
export const selectSelectedSerialNumbers = (state) =>
  state.binTransfer.selectedSerialNumbers;
export const selectSerialNumbersWithStatus = (state) =>
  state.binTransfer.binTransferItem.sublineItems.map((sublineItem) => ({
    serialNumber: sublineItem.serialNumber,
    isSelected: state.binTransfer.selectedSerialNumbers.includes(
      sublineItem.serialNumber
    )
  }));
export const selectRecommendedBins = (state) =>
  state.binTransfer.recommendedBins;

export default binTransferSlice.reducer;
