import React, { useState } from 'react';
import cx from 'classnames';
import { Button, Row, Col, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

const PickLocationTransferItemDetailsFooterBtns = ({
  isNextPick,
  isReadyToFulfill,
  otherActivePickers,
  navigateToPickList,
  navigateToNextItem,
  handleFulfillPickedItems
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleClickFulfill = () => {
    if (otherActivePickers.length) {
      return toggle();
    }

    return handleFulfillPickedItems();
  };

  return (
    <>
      <Row className='w-100 d-flex'>
        <Col
          className={cx('pl-0 pr-0', {
            'pr-2': isNextPick || isReadyToFulfill
          })}
        >
          <Button
            color='warning'
            block
            onClick={navigateToPickList}
            className='h-100'
          >
            Pick List
          </Button>
        </Col>
        {isNextPick && !isReadyToFulfill && (
          <Col className='pr-0 pl-2'>
            <Button
              color='primary'
              block
              onClick={navigateToNextItem}
              className='h-100'
            >
              Next Item
            </Button>
          </Col>
        )}
        {isReadyToFulfill && (
          <Col className='pr-0 pl-2'>
            <Button
              color='primary'
              block
              onClick={handleClickFulfill}
              className='h-100'
            >
              Fulfill
            </Button>
          </Col>
        )}
      </Row>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>{`Are you sure you want to fulfill items picked by ${otherActivePickers.join(
                ', '
              )}?`}</h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button color='primary' outline block onClick={toggle}>
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button color='primary' block onClick={handleFulfillPickedItems}>
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

PickLocationTransferItemDetailsFooterBtns.propTypes = {
  isNextPick: PropTypes.string,
  isReadyToFulfill: PropTypes.bool.isRequired,
  otherActivePickers: PropTypes.array.isRequired,
  navigateToPickList: PropTypes.func.isRequired,
  navigateToNextItem: PropTypes.func.isRequired,
  handleFulfillPickedItems: PropTypes.func.isRequired
};

export default PickLocationTransferItemDetailsFooterBtns;
