import React, { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSalesOrderItem,
  selectNextPick,
  selectOtherActivePickers,
  fulfillPickedItems,
  fetchSalesOrderNextPick,
  completeSalesOrderPick
} from 'pages/Picking/PickSalesOrder/pickSalesOrderSlice';
import PickItemDetailsFooter from './components/PickItemDetailsFooter';

const PickSalesOrderItemDetailsFooter = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch('/picking/pick-sales-order/:salesOrderNumber');
  const nextPick = useSelector(selectNextPick);
  const otherActivePickers = useSelector(selectOtherActivePickers);
  const { isReadyToDoubleCheck, isReadyToFulfill } =
    useSelector(selectSalesOrderItem);

  useEffect(() => {
    if (match?.params?.salesOrderNumber) {
      dispatch(fetchSalesOrderNextPick(match?.params?.salesOrderNumber));
    }
  }, [dispatch, match?.params?.salesOrderNumber]);

  const isNextPick = nextPick.bin && nextPick.sku;
  const navigateToPickList = () =>
    history.replace(
      `/picking/pick-sales-order/${match?.params?.salesOrderNumber}`
    );

  const navigateToNextItem = () =>
    history.replace(
      `/picking/pick-sales-order/${match?.params?.salesOrderNumber}/pick-bin/${nextPick.bin}/item-details/${nextPick.sku}`
    );
  const handleCompletePick = async () => {
    dispatch(completeSalesOrderPick(match?.params?.salesOrderNumber)).then(
      () => {
        history.replace('/picking');
      }
    );
  };

  const handleFulfillPickedItems = async () => {
    dispatch(fulfillPickedItems(match?.params?.salesOrderNumber)).then(
      (res) => {
        if (res) {
          history.replace('/picking/pick-sales-order');
        }
      }
    );
  };

  return (
    <PickItemDetailsFooter
      isNextPick={isNextPick}
      isReadyToDoubleCheck={isReadyToDoubleCheck}
      isReadyToFulfill={isReadyToFulfill}
      otherActivePickers={otherActivePickers}
      navigateToPickList={navigateToPickList}
      navigateToNextItem={navigateToNextItem}
      handleCompletePick={handleCompletePick}
      handleFulfillItems={handleFulfillPickedItems}
    />
  );
};

export default PickSalesOrderItemDetailsFooter;
