import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Progress } from 'reactstrap';
import { selectSalesOrderItem } from 'pages/Picking/PickSalesOrder/pickSalesOrderSlice';
import { setAlertColor } from '../alertSlice';

const calcValue = (progress) => {
  if (!progress) {
    return 0;
  }
  const numericArr = progress.split('/');
  return (numericArr[0] / numericArr[1]) * 100;
};

const ShipmentDetailsAlert = () => {
  const dispatch = useDispatch();
  const salesOrderItem = useSelector(selectSalesOrderItem);

  useEffect(() => {
    dispatch(setAlertColor('primary'));
  }, [dispatch, salesOrderItem.orderPickStatus]);

  return (
    <Row className='mt-0 mb-1 font-weight-semibold w-100 text-dark'>
      <Col>
        <Row className='mb-2'>
          <Col xs='auto' className='text-left pl-2'>
            <p className='m-0'>{salesOrderItem.orderNumber}</p>
          </Col>
          <Col className='text-right pr-2'>
            <p className='m-0'>{salesOrderItem.orderPickProgress} Picked</p>
          </Col>
        </Row>
        <Row>
          <Col className='px-2'>
            <Progress
              color='primary'
              value={calcValue(salesOrderItem.orderPickProgress)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ShipmentDetailsAlert;
