/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

export const managePalletsSlice = createSlice({
  name: 'managePallets',
  initialState: {
    pallets: [],
    pallet: {
      id: null,
      barcode: null,
      quantity: null,
      items: []
    }
  },
  reducers: {
    getOpenPalletsSuccess: (state, { payload }) => {
      state.pallets = payload;
    },
    getPalletSuccess: (state, { payload }) => {
      state.pallet = payload;
    }
  }
});

export const { getOpenPalletsSuccess, getPalletSuccess } =
  managePalletsSlice.actions;

export const fetchOpenPalletsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    const warehouseId = getState().authentication.location.id;
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/pallet/open/${warehouseId}`);
      dispatch(getOpenPalletsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const fetchPalletById =
  (id) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/pallet/${id}`);
      dispatch(getPalletSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const checkPalletExistsAtLocation =
  (barcode) =>
  async (dispatch, getState, { imsAPI }) => {
    const warehouseId = getState().authentication.location.id;
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(
        `/pallet/${barcode}/existence/${warehouseId}`
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const removeItemFromPallet =
  (sublineItemId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const warehouseId = getState().authentication.location.id;
      const { id, barcode } = getState().managePallets.pallet;
      dispatch(setLoading(true));
      const res = await imsAPI.post(`/pallet/${id}/remove-item`, {
        warehouseId,
        sublineItemId
      });
      dispatch(getPalletSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(`An item was successfully removed from Pallet ${barcode}`);
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const breakPallet =
  (id, barcode) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      await imsAPI.delete(`/pallet/${id}`);
      dispatch(setLoading(false));
      toast.warning(`Pallet ${barcode} successfully broken`);
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const createPallet =
  (barcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const warehouseId = getState().authentication.location.id;
      dispatch(setLoading(true));
      const res = await imsAPI.post(`/pallet/create`, { warehouseId, barcode });
      dispatch(setLoading(false));
      toast.success(`Pallet ${barcode} successfully created`);
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const addItemToPallet =
  (serialNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const {
        authentication: {
          location: { id: warehouseId }
        },
        managePallets: {
          pallet: { id, barcode }
        }
      } = getState();
      dispatch(setLoading(true));
      const res = await imsAPI.post(`/pallet/${id}/add-item`, {
        warehouseId,
        serialNumber
      });
      dispatch(getPalletSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `Item with serial ${serialNumber} successfully added to Pallet ${barcode}`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const updatePalletBarcode =
  (newBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const {
        managePallets: { pallet }
      } = getState();

      dispatch(setLoading(true));

      const res = await imsAPI.put(`/pallet/${pallet.id}/barcode`, {
        barcode: newBarcode
      });

      dispatch(getPalletSuccess({ ...pallet, barcode: res.data.barcode }));
      dispatch(setLoading(false));

      toast.success(
        `Pallet number/barcode has been successfully updated to ${newBarcode}`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectOpenPallets = (state) => state.managePallets.pallets;
export const selectPallet = (state) => state.managePallets.pallet;

export default managePalletsSlice.reducer;
