import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'components/Elements';
import SalesOrderPalletUpdateModal from 'components/SalesOrderPalletUpdate/SalesOrderPalletUpdateModal';
import {
  closeSalesOrderPallet,
  updateSalesOrderPallet,
  removePalletFromSalesOrder,
  selectSalesOrder,
  selectSalesOrderPallet
} from 'pages/DoubleCheck/DoubleCheckSalesOrder/doubleCheckSalesOrderSlice';

const DoubleCheckSalesOrderPalletDetailsBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch(
    '/double-check/:salesOrderNumber/pallet-details/:palletBarcode'
  );
  const salesOrder = useSelector(selectSalesOrder);
  const salesOrderPallet = useSelector(selectSalesOrderPallet);
  const [closePalletModal, setClosePalletModal] = useState(false);

  const updatePallet = async ({
    cartonCount,
    length,
    width,
    height,
    palletInventoryItemId
  }) => {
    dispatch(
      updateSalesOrderPallet({
        salesOrderNumber: match?.params?.salesOrderNumber,
        palletBarcode: match?.params?.palletBarcode,
        payload: {
          cartonCount,
          length,
          width,
          height,
          palletInventoryItemId
        }
      })
    );
  };

  const onModalCloseSuccess = () => {
    dispatch(
      closeSalesOrderPallet(
        match?.params?.salesOrderNumber,
        salesOrderPallet.palletId
      )
    ).then((res) => {
      if (res) {
        history.replace(`/double-check/${salesOrder.orderNumber}`);
      }
    });
  };

  const handleRemovePalletFromSalesOrder = async () => {
    dispatch(
      removePalletFromSalesOrder(
        match?.params?.salesOrderNumber,
        salesOrderPallet.palletId,
        salesOrderPallet.palletBarcode
      )
    ).then((res) => {
      if (res) {
        history.replace(`/double-check/${salesOrder.orderNumber}`);
      }
    });
  };

  return (
    <>
      <Button
        color='danger'
        block
        onClick={() => handleRemovePalletFromSalesOrder()}
        className='mr-1'
      >
        Remove Pallet
      </Button>
      {salesOrderPallet.sublineItems.length > 0 && (
        <Button
          color='primary'
          block
          onClick={() => setClosePalletModal((prev) => !prev)}
          className='mt-0'
        >
          Close Pallet
        </Button>
      )}

      {!!closePalletModal && (
        <SalesOrderPalletUpdateModal
          palletBarcode={match?.params?.palletBarcode}
          steps={['cartonCount', 'dimensions', 'height']}
          updatePallet={updatePallet}
          modalCloseSuccess={onModalCloseSuccess}
        />
      )}
    </>
  );
};

export default DoubleCheckSalesOrderPalletDetailsBtn;
