import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Button } from 'components/Elements';
import SalesOrderProcessCompletedModal from 'components/SalesOrderProcessCompletedModal/SalesOrderProcessCompletedModal';
import { finalizeReviewChecklist } from '../../../pages/DoubleCheck/DoubleCheckSalesOrder/doubleCheckSalesOrderSlice';

const DoubleCheckSalesOrderReviewCheckListFooter = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/double-check/:salesOrderNumber');
  const [isProcessCompletedModal, setIsProcessCompletedModal] = useState(false);

  const toggleProcessCompletedModal = () => {
    setIsProcessCompletedModal(!isProcessCompletedModal);
  };

  const handleFinalizeDoubleCheck = async () => {
    dispatch(finalizeReviewChecklist(match?.params?.salesOrderNumber)).then(
      (res) => {
        if (res) {
          toggleProcessCompletedModal();
        }
      }
    );
  };

  const handleClick = () => {
    handleFinalizeDoubleCheck();
  };

  return (
    <>
      <SalesOrderProcessCompletedModal
        isOpen={isProcessCompletedModal}
        toggle={toggleProcessCompletedModal}
        currentProcess='Double-Checking'
        nextProcess='Wrap/Weigh'
        redirect='/wrap'
        link='/double-check'
        linkText='Return to Double-Checking'
      />
      <Row className='w-100 d-flex'>
        <Col className='p-0'>
          <Button color='primary' block onClick={handleClick}>
            Finalize Double-Check
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DoubleCheckSalesOrderReviewCheckListFooter;
