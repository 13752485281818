import React from 'react';
import { Row, Col } from 'reactstrap';

const ShipmentDetailsAlert = () => (
  <Row className='my-2'>
    <Col>
      <p className='font-weight-normal m-0 font-italic'>
        You are viewing another location.
      </p>
    </Col>
  </Row>
);

export default ShipmentDetailsAlert;
