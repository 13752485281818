import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useAuthProvider } from '@alpinehomeair/alpine-admin';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import DesktopHomeButton from 'components/DesktopHomeButton/DesktopHomeButton';
import profileIcon from 'assets/icons/person-circle.svg';
import backIcon from 'assets/icons/chevron-left.svg';
import ellipsis from 'assets/icons/three-dots-vertical.svg';
import { useSelector, useDispatch } from 'react-redux';
import useIsMobileDevice from 'utils/hooks/useIsMobileDevice';
import {
  logout,
  selectLocation,
  selectUserFirstName,
  selectIsLocationRestricted
} from 'components/Authentication/authenticationSlice';
import {
  selectNavigationTitle,
  selectNavigationMenuActions
} from './navigationSlice';

const Navigation = () => {
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const location = useLocation();
  const history = useHistory();
  const title = useSelector(selectNavigationTitle);
  const menuActions = useSelector(selectNavigationMenuActions);
  const userLocation = useSelector(selectLocation);
  const userFirstName = useSelector(selectUserFirstName);
  const isLocationRestricted = useSelector(selectIsLocationRestricted);
  const isMobileDevice = useIsMobileDevice();

  const navigateToPrevious = () => {
    const slugs = location.pathname.split('/');
    const newUrl = slugs.splice(0, slugs.length - 1).join('/');
    history.replace(newUrl);
  };

  const isHome = location.pathname === '/';
  const isLogin = location.pathname === '/login';

  const signOut = async () => {
    authProvider.logout();
    await dispatch(logout());
    history.replace('/login');
  };

  return (
    <Navbar color='primary' dark expand='md' className='py-1 px-2'>
      {isLogin && (
        <div className='d-flex align-items-center'>
          {!isMobileDevice && <DesktopHomeButton />}
          <NavbarBrand className='mx-auto' href='/'>
            Yellow Supply Login
          </NavbarBrand>
        </div>
      )}
      {isHome && (
        <div className='d-flex align-items-center'>
          {!isMobileDevice && <DesktopHomeButton />}
          <NavbarBrand className='mr-auto' href='/'>
            {userLocation.name}
          </NavbarBrand>
        </div>
      )}
      {!isHome && !isLogin && (
        <div className='d-flex align-items-center'>
          {!isMobileDevice && <DesktopHomeButton />}
          <NavLink className='mr-auto p-0 btn' onClick={navigateToPrevious}>
            <img
              key={backIcon}
              src={backIcon}
              alt='back'
              width='24'
              height='24'
            />
          </NavLink>
        </div>
      )}
      {!isHome && title && (
        <NavbarBrand className='mx-auto text-truncate w-75 text-center'>
          {title}
        </NavbarBrand>
      )}
      {!isLogin && (
        <Nav className='d-flex flex-row justify-content-center ml-auto' navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle nav className='show'>
              {isHome && (
                <>
                  <NavbarText className='p-0 mr-2'>{userFirstName}</NavbarText>
                  <img
                    key={profileIcon}
                    src={profileIcon}
                    alt='profile'
                    width='24'
                    height='24'
                  />
                </>
              )}
              {!isHome && (
                <img
                  key={ellipsis}
                  src={ellipsis}
                  alt='profile'
                  width='24'
                  height='24'
                />
              )}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>{userLocation.name}</DropdownItem>
              {!isLocationRestricted && (
                <>
                  <DropdownItem divider />
                  <DropdownItem>
                    <Link to='/change-location'>Change Location</Link>
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              )}
              {menuActions.map((action) => (
                <DropdownItem
                  key={action.label}
                  onClick={() => history.replace(action.route)}
                >
                  {action.label}
                </DropdownItem>
              ))}
              {!!menuActions.length && <DropdownItem divider />}
              <DropdownItem>
                <a href='#' onClick={signOut}>
                  Sign Out
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      )}
    </Navbar>
  );
};

export default Navigation;
