import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import {
  selectShipment,
  completeShipmentInspection
} from 'pages/Receiving/InspectShipment/inspectShipmentSlice';

const InspectItemDetailsBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shipment = useSelector(selectShipment);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const handleSubmit = async () => {
    const res = await dispatch(completeShipmentInspection(shipment.id));
    if (res) {
      history.replace(`/receiving/bin-putaway`);
    }
  };
  const handleClick = async () => {
    if (shipment.inspectionStatus === 'Complete') {
      handleSubmit();
    } else {
      toggle();
    }
  };

  return (
    <>
      <Button
        color={cx({
          warning:
            shipment.inspectionStatus === 'In Progress' ||
            shipment.inspectionStatus === 'Pending',
          primary: shipment.inspectionStatus === 'Complete'
        })}
        block
        onClick={handleClick}
      >
        Create Item Receipt
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>
                All items have not been inspected. Are you sure you want create
                an item receipt?
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button color='primary' outline block onClick={toggle}>
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button color='primary' block onClick={handleSubmit}>
                Continue
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InspectItemDetailsBtn;
