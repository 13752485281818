import React, { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectLocationTransferItem,
  selectNextPick,
  selectOtherActivePickers,
  fetchLocationTransferNextPick,
  fulfillPickedItems
} from 'pages/Picking/PickLocationTransfer/pickLocationTransferSlice';
import PickLocationTransferItemDetailsFooterBtns from './components/PickLocationTransferItemDetailsFooterBtns';

const PickLocationTransferItemDetailsFooter = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch(
    '/picking/pick-location-transfer/:locationTransferId'
  );
  const nextPick = useSelector(selectNextPick);
  const otherActivePickers = useSelector(selectOtherActivePickers);
  const { isReadyToFulfill } = useSelector(selectLocationTransferItem);

  useEffect(() => {
    if (match?.params?.locationTransferId) {
      dispatch(
        fetchLocationTransferNextPick(match?.params?.locationTransferId)
      );
    }
  }, [dispatch, match?.params?.locationTransferId]);

  const handleFulfillPickedItems = async () => {
    const res = await dispatch(
      fulfillPickedItems(match?.params?.locationTransferId)
    );

    if (res) {
      history.replace('/picking/pick-location-transfer');
    }
  };

  const navigateToPickList = () =>
    history.replace(
      `/picking/pick-location-transfer/${match?.params?.locationTransferId}`
    );

  const navigateToNextItem = () =>
    history.replace(
      `/picking/pick-location-transfer/${match?.params?.locationTransferId}/pick-bin/${nextPick.bin}/item-details/${nextPick.sku}`
    );

  const isNextPick = nextPick.bin && nextPick.sku;

  return (
    <PickLocationTransferItemDetailsFooterBtns
      isNextPick={isNextPick}
      isReadyToFulfill={isReadyToFulfill}
      otherActivePickers={otherActivePickers}
      navigateToPickList={navigateToPickList}
      navigateToNextItem={navigateToNextItem}
      handleFulfillPickedItems={handleFulfillPickedItems}
    />
  );
};

export default PickLocationTransferItemDetailsFooter;
