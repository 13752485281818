import React, { useState } from 'react';
import { Button } from 'reactstrap';
import CreatePalletModal from 'pages/ManageBinItems/components/CreatePalletModal';

const CreatePalletBtn = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <>
      <Button color='primary' block onClick={toggle}>
        Create Pallet
      </Button>
      <CreatePalletModal isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export default CreatePalletBtn;
