/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

export const receivingSlice = createSlice({
  name: 'receiving',
  initialState: {
    notifications: {
      dockShipment: 0,
      receiveDoor: 0,
      inspectShipment: 0,
      binPutaway: 0
    }
  },
  reducers: {
    getNotificationsSuccess: (
      state,
      { payload: { in_transit, in_door, in_inspection, in_putaway } }
    ) => {
      state.notifications.dockShipment = in_transit;
      state.notifications.receiveDoor = in_door;
      state.notifications.inspectShipment = in_inspection;
      state.notifications.binPutaway = in_putaway;
    }
  }
});

export const { getNotificationsSuccess } = receivingSlice.actions;
export const fetchShipmentNotificationsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/shipment/location/${locationId}/notifications`
      );
      dispatch(getNotificationsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const selectNotifications = (state) => state.receiving.notifications;

export default receivingSlice.reducer;
