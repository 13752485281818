export default [
  {
    locationId: 9,
    locationName: 'Shepherdsville'
  },
  {
    locationId: 10,
    locationName: 'Fountain Hill'
  },
  {
    locationId: 11,
    locationName: 'Sparks'
  }
];
