import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const BinPutawayItemDetailsBtn = () => {
  const history = useHistory();
  const match = useRouteMatch('/receiving/bin-putaway/item-details/:itemSKU');

  return (
    <Button
      color='primary'
      block
      onClick={() => {
        history.replace(
          `/receiving/bin-putaway/item-details/${match?.params?.itemSKU}/confirmation`
        );
      }}
    >
      Confirm Qty
    </Button>
  );
};

export default BinPutawayItemDetailsBtn;
