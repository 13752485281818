import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import {
  selectLocationTransfer,
  selectLocationTransferActivePickers,
  fulfillPickedItems
} from 'pages/Picking/PickLocationTransfer/pickLocationTransferSlice';
import { selectUser } from 'components/Authentication/authenticationSlice';

const PickLocationTransferDetailsBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activePickers = useSelector(selectLocationTransferActivePickers);
  const user = useSelector(selectUser);
  const { locationTransferId } = useSelector(selectLocationTransfer);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleFulfillPickedItems = async () => {
    const res = await dispatch(fulfillPickedItems(locationTransferId));

    if (res) {
      history.replace('/picking/pick-location-transfer');
    }
  };

  const otherActivePickers = useMemo(
    () => activePickers.filter((picker) => picker !== user.name),
    [activePickers, user.name]
  );

  const handleClick = () => {
    if (otherActivePickers.length) {
      return toggle();
    }

    return handleFulfillPickedItems();
  };

  return (
    <>
      <Button color='primary' block onClick={handleClick}>
        Fulfill Picked Items
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>{`Are you sure you want to fulfill items picked by ${otherActivePickers.join(
                ', '
              )}?`}</h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button color='primary' outline block onClick={toggle}>
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button color='primary' block onClick={handleFulfillPickedItems}>
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PickLocationTransferDetailsBtn;
