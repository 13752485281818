import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
  completeSalesOrderPallet,
  selectIsAllPalletPhotosUploaded
} from '../../../pages/Wrap/WrapSalesOrder/wrapSalesOrderSlice';

const WrapSalesOrderPalletDetailsBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch(
    '/wrap/:salesOrderNumber/pallet-details/:palletBarcode'
  );
  const [btnDisabled, setBtnDisabled] = useState(true);
  const isAllPalletPhotosUploaded = useSelector(
    selectIsAllPalletPhotosUploaded
  );

  useEffect(() => {
    setBtnDisabled(!isAllPalletPhotosUploaded);
  }, [isAllPalletPhotosUploaded]);

  const handleOnClick = () => {
    dispatch(
      completeSalesOrderPallet(
        match?.params?.salesOrderNumber,
        match?.params?.palletBarcode
      )
    ).then((res) => {
      if (res) {
        history.push(`/wrap/${match?.params?.salesOrderNumber}`);
      }
    });
  };

  return (
    <Button
      color='primary'
      disabled={btnDisabled}
      block
      onClick={handleOnClick}
    >
      Complete Pallet
    </Button>
  );
};

export default WrapSalesOrderPalletDetailsBtn;
