import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const Scanner = ({
  children,
  fieldName,
  unmountToPrevious,
  shouldMount = true
}) => {
  const formikProps = useFormikContext();

  useEffect(() => {
    const previousBarcodeData = window.BarcodeData;

    return () => {
      window.BarcodeData = unmountToPrevious ? previousBarcodeData : undefined;
    };
  }, [unmountToPrevious]);

  useEffect(() => {
    if (shouldMount) {
      window.BarcodeData = (barcode) => {
        formikProps.setFieldValue(fieldName, barcode);
        formikProps.submitForm();
      };
    }
  }, [fieldName, formikProps, shouldMount]);

  return children;
};

export default Scanner;
