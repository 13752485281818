import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import carrierValidator from 'utils/validation/carrierValidator';
import {
  createTruck,
  fetchCarriers,
  selectCarriers,
  selectDockCarriers
} from 'pages/ManageDock/manageDockSlice';

const ManageDockOutboundBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isUnassignedCarrierInDock, setIsUnassignedCarrierInDock] =
    useState(false);
  const carriers = useSelector(selectCarriers);
  const dockCarriers = useSelector(selectDockCarriers);

  const toggle = () => {
    if (isOpen) {
      setIsUnassignedCarrierInDock(false);
    }
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    dispatch(fetchCarriers());
  }, [dispatch]);

  const findCarrier = (carrierId) => {
    return dockCarriers.find(
      (carrier) => carrier.carrierId.toString() === carrierId
    );
  };

  const createTruckHandler = async ({ carrierId, existingCarrier }) => {
    const allCarrierTruck = dockCarriers.filter(
      (carrier) => carrier.carrierId.toString() === carrierId
    );
    const isCarrierInDockClosed = allCarrierTruck.every((carrier) =>
      ['Closed In Door', 'Shipped'].includes(carrier.truckStatus)
    );

    const carrier = findCarrier(carrierId) || {
      poPalletIds: [],
      carrierCode: ''
    };

    const { poPalletIds, carrierCode } = carrier;

    if (!isCarrierInDockClosed) {
      toast.error(`Please close the truck for ${carrierCode} Carrier first`);
      return;
    }

    const res = await dispatch(
      existingCarrier
        ? createTruck(carrierId, poPalletIds)
        : createTruck(carrierId)
    );

    if (res.name) {
      history.push(`/manage-dock/truck-details/${res.name}`);
    }
  };

  const selectCarrier = (e) => {
    const unassignedCarriers = dockCarriers.filter(
      (carrier) =>
        carrier.carrierId.toString() === e.target.value &&
        !carrier.warehouseDoorName &&
        carrier.palletOnTruckCount > 0
    );

    if (unassignedCarriers.length) {
      setIsUnassignedCarrierInDock(true);
    } else {
      setIsUnassignedCarrierInDock(false);
    }
  };

  return (
    <>
      <Button color='primary' block onClick={toggle}>
        Create Truck
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalBody>
          <Formik
            initialValues={{ carrierId: '', existingCarrier: false }}
            validationSchema={carrierValidator}
            onSubmit={createTruckHandler}
          >
            {({ setFieldValue, values }) => (
              <Form autoComplete='off'>
                <h6>Select a Carrier to Create Truck</h6>
                <Field
                  as='select'
                  name='carrierId'
                  className='form-control mb-3'
                  value={values.carrierId}
                  onChange={(e) => {
                    setFieldValue('carrierId', e.target.value);
                    selectCarrier(e);
                  }}
                >
                  <option value=''>Select a Carrier</option>
                  {carriers.map((carrier) => (
                    <option key={carrier.ID} value={carrier.ID}>
                      {carrier.name} - {carrier.code}
                    </option>
                  ))}
                </Field>
                {isUnassignedCarrierInDock && (
                  <label className='mb-4'>
                    <Field type='checkbox' name='existingCarrier' />
                    &nbsp; Would you like to create a truck including available
                    orders?
                  </label>
                )}
                <Row>
                  <Col>
                    <Button color='danger' block onClick={toggle}>
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button color='primary' block type='submit'>
                      Create Truck
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ManageDockOutboundBtn;
