/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

const initialState = {
  binPutawayEntities: {
    pallets: [],
    items: []
  },
  binPutawayItem: {
    model: '',
    manufacturer: '',
    imageUrl: '',
    isSerialized: false,
    inventoryItemId: null,
    sublineItems: [],
    quantity: null
  },
  unSerializedSelectedQuantity: 0,
  selectedSerialNumbers: [],
  recommendedBins: []
};

export const binPutawaySlice = createSlice({
  name: 'binPutaway',
  initialState,
  reducers: {
    getBinPutawayEntitiesSuccess: (state, { payload }) => {
      state.binPutawayEntities = payload;
    },
    getBinPutawayItemSuccess: (state, { payload }) => {
      state.binPutawayItem = payload;
    },
    getRecommendedBinsSuccess: (state, { payload }) => {
      state.recommendedBins = payload;
    },
    setUnSerializedSelectedQuantity: (state, { payload }) => {
      state.unSerializedSelectedQuantity = payload;
    },
    selectSerialNumberSuccess: (state, { payload }) => {
      state.selectedSerialNumbers = [...state.selectedSerialNumbers, payload];
    },
    deselectSerialNumberSuccess: (state, { payload }) => {
      state.selectedSerialNumbers = state.selectedSerialNumbers.filter(
        (serialNumber) => serialNumber !== payload
      );
    },
    resetBinPutaway: (state) => {
      state.binPutawayEntities = initialState.binPutawayEntities;
      state.binPutawayItem = initialState.binPutawayItem;
      state.unSerializedSelectedQuantity =
        initialState.unSerializedSelectedQuantity;
      state.selectedSerialNumbers = initialState.selectedSerialNumbers;
      state.recommendedBins = initialState.recommendedBins;
    }
  }
});

export const {
  getBinPutawayEntitiesSuccess,
  getBinPutawayItemSuccess,
  getRecommendedBinsSuccess,
  setUnSerializedSelectedQuantity,
  selectSerialNumberSuccess,
  deselectSerialNumberSuccess,
  resetBinPutaway
} = binPutawaySlice.actions;

export const fetchBinPutawayEntitiesByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/putaway-staging/warehouse/${warehouseId}`
      );
      dispatch(getBinPutawayEntitiesSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const fetchBinPutawayEntity =
  (entityBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/putaway-staging/warehouse/${warehouseId}/entity/${entityBarcode}`
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const fetchReceivingItemBySKU =
  (sku) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/putaway-staging/warehouse/${warehouseId}/item/${sku}`
      );
      dispatch(getBinPutawayItemSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const putawaySerializedItem =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const {
        selectedSerialNumbers,
        binPutawayItem: { model, manufacturer, inventoryItemId, sublineItems }
      } = getState().binPutaway;
      const formattedSublineItems = sublineItems.reduce(
        (acc, { serialNumber, sublineItemId }) => {
          if (selectedSerialNumbers.includes(serialNumber)) {
            acc.push({ id: sublineItemId });
          }
          return acc;
        },
        []
      );

      await imsAPI.post('/bin/items/putaway', {
        warehouseId,
        destinationBinBarcode: binBarcode,
        inventoryItemId,
        sublineItems: formattedSublineItems
      });
      dispatch(setLoading(false));
      toast.success(
        `Bin putaway of ${selectedSerialNumbers.length} ${manufacturer} ${model} to ${binBarcode} has been Successful`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const putawayUnserializedItem =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const {
        unSerializedSelectedQuantity,
        binPutawayItem: { inventoryItemId, model, manufacturer, sublineItems }
      } = getState().binPutaway;
      const formattedSublineItems = sublineItems
        .slice(0, unSerializedSelectedQuantity)
        .map(({ sublineItemId }) => ({ id: sublineItemId }));

      await imsAPI.post(`/bin/items/putaway`, {
        warehouseId,
        inventoryItemId,
        destinationBinBarcode: binBarcode,
        sublineItems: formattedSublineItems
      });
      dispatch(setLoading(false));
      toast.success(
        `Bin putaway of ${unSerializedSelectedQuantity} ${manufacturer} ${model} to ${binBarcode} has been Successful`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const putawayPallet =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const { barcode, id, items } = getState().managePallets.pallet;
      const distinctItems = items.map(({ inventoryItemId, sublineItems }) => {
        return {
          warehouseId,
          destinationBinBarcode: binBarcode,
          inventoryItemId,
          sublineItems: sublineItems.map(({ sublineItemId }) => ({
            id: sublineItemId
          }))
        };
      });
      await imsAPI.post(`/bin/pallet/putaway`, {
        id,
        items: distinctItems
      });
      dispatch(setLoading(false));
      toast.success(
        `Bin putaway of Pallet ${barcode} to ${binBarcode} has been Successful`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const fetchPalletRecommendedBins =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const { items } = getState().managePallets.pallet;
      const sublineItemIdList = items
        .reduce((acc, item) => {
          acc = [
            ...acc,
            item.sublineItems.map((sublineItem) => sublineItem.sublineItemId)
          ];
          return acc;
        }, [])
        .join(',');
      const res = await imsAPI.post(`bin/recommended/`, {
        sublineItemIdList
      });
      dispatch(getRecommendedBinsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };
export const fetchItemRecommendedBins =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const { sublineItems } = getState().binPutaway.binPutawayItem;
      const sublineItemIdList = sublineItems
        .map((sublineItem) => sublineItem.sublineItemId)
        .join(', ');
      const res = await imsAPI.post(`bin/recommended/`, {
        sublineItemIdList
      });
      dispatch(getRecommendedBinsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };
export const selectSerialNumber = (serialNumber) => (dispatch, getState) => {
  const {
    selectedSerialNumbers,
    binPutawayItem: { sublineItems }
  } = getState().binPutaway;
  const isAvailable = sublineItems.some(
    (sublineItem) => sublineItem.serialNumber === serialNumber
  );
  const isAlreadySelected = selectedSerialNumbers.some(
    (selectedSerialNumber) => selectedSerialNumber === serialNumber
  );
  if (!isAvailable) {
    toast.error(
      `Serial ${serialNumber} is not associated with this item or not in receiving`
    );
  }
  if (isAlreadySelected) {
    toast.error(
      `Serial ${serialNumber} has already been selected for bin putaway`
    );
  }
  if (isAvailable && !isAlreadySelected) {
    dispatch(selectSerialNumberSuccess(serialNumber));
    toast.success(`Serial ${serialNumber} has been selected for bin putaway`, {
      autoClose: 2000
    });
  }
};
export const deselectSerialNumber = (serialNumber) => (dispatch) => {
  dispatch(deselectSerialNumberSuccess(serialNumber));
  toast.warning(`Serial ${serialNumber} has been deselected for bin putaway`, {
    autoClose: 2000
  });
};

export const selectBinPutawayEntities = (state) =>
  state.binPutaway.binPutawayEntities;
export const selectBinPutawayItem = (state) => state.binPutaway.binPutawayItem;
export const selectUnSerializedSelectedQuantity = (state) =>
  state.binPutaway.unSerializedSelectedQuantity;
export const selectSelectedSerialNumbers = (state) =>
  state.binPutaway.selectedSerialNumbers;
export const selectSerialNumbersWithStatus = (state) =>
  state.binPutaway.binPutawayItem.sublineItems.map((sublineItem) => ({
    serialNumber: sublineItem.serialNumber,
    isSelected: state.binPutaway.selectedSerialNumbers.includes(
      sublineItem.serialNumber
    )
  }));
export const selectRecommendedBins = (state) =>
  state.binPutaway.recommendedBins;

export default binPutawaySlice.reducer;
