import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Badge as BadgeComponent } from 'reactstrap';

const getFontSize = (fontSize) => {
  switch (fontSize) {
    case 'sm':
      return '12px';
    case 'lg':
      return '16px';
    default:
      return '14px';
  }
};

const StyledBadgeComponent = styled(BadgeComponent)`
  font-size: ${({ fontSize }) => getFontSize(fontSize)};
  font-weight: 600;
  padding: 3px 6px;
`;

const Badge = ({
  color = 'primary',
  pill = false,
  className,
  fontSize = 'md',
  children
}) => {
  return (
    <StyledBadgeComponent
      color={color}
      pill={pill}
      className={className}
      fontSize={fontSize}
    >
      {children}
    </StyledBadgeComponent>
  );
};

Badge.propTypes = {
  color: PropTypes.string,
  pill: PropTypes.bool,
  className: PropTypes.string,
  fontSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  children: PropTypes.node.isRequired
};

export default Badge;
