/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';
import { formatImageUrl } from 'utils/formatters';

const initialState = {
  name: 'manageBinItems',
  binItem: {
    sectionName: '',
    binName: '',
    model: '',
    manufacturer: '',
    imageUrl: '',
    isSerialized: false,
    inventoryItemId: null,
    sku: null,
    warehouseId: null,
    sublineItems: []
  },
  palletId: {
    sectionName: '',
    binName: '',
    sublineItems: []
  },
  bin: {
    sectionName: '',
    binName: '',
    binEntities: {
      pallets: [],
      items: []
    }
  },
  serial: {
    pickSublineItemBySerial: '',
    sublineSerialStatus: null
  }
};

export const manageBinItemsSlice = createSlice({
  name: 'manageBinItemsSlice',
  initialState,
  reducers: {
    getBinItemSuccess: (state, { payload }) => {
      state.binItem = payload;
    },
    getBinSuccess: (state, { payload }) => {
      state.bin = payload;
    },
    deselectSerialNumberSuccess: (state, { payload }) => {
      state.selectedSerialNumbers = state.selectedSerialNumbers.filter(
        (serialNumber) => serialNumber !== payload
      );
    },
    validateSerialSuccess: (state, { payload }) => {
      state.serial.pickSublineItemBySerial = payload;
    },
    sublineSerializedStatus: (state, { payload }) => {
      state.serial.sublineSerialStatus = payload;
    },
    getPalletItemSuccess: (state, { payload }) => {
      state.palletId.sublineItems = payload;
    }
  }
});

export const {
  getBinItemSuccess,
  getBinSuccess,
  deselectSerialNumberSuccess,
  getPalletItemSuccess,
  validateSerialSuccess,
  sublineSerializedStatus
} = manageBinItemsSlice.actions;

export const getBinSublineItems =
  (binCode, sku) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/subline-items/${binCode}/${sku}`);
      const { photoPath, ...binItemData } = res.data;
      dispatch(
        getBinItemSuccess({
          ...binItemData,
          imageUrl: formatImageUrl(photoPath)
        })
      );

      dispatch(setLoading(false));
      const serial = binItemData.sublineItems.filter(
        (item) => item.serialNumber !== ''
      ).length;
      if (serial > 0) {
        dispatch(sublineSerializedStatus(true));
      }

      return true;
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };
export const fetchBinByLocationIdAndBinName =
  (binBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/${binBarcode}/warehouse/${warehouseId}`
      );
      dispatch(getBinSuccess(res.data));
      dispatch(setLoading(false));
      return { type: 'Success' };
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return { type: 'Failure' };
    }
  };
export const fetchBinTransferEntity =
  (binBarcode, entityBarcode) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));

      const res = await imsAPI.get(
        `/bin/${binBarcode}/entity/${encodeURIComponent(entityBarcode)}`
      );

      dispatch(setLoading(false));

      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));

      dispatch(setLoading(false));

      return false;
    }
  };

export const pickSublineItemBySerial =
  (serialNumber, binCode) =>
  async (dispatch, getState, { imsAPI }) => {
    const { sublineItems, inventoryItemId, sku, warehouseId } =
      getState().manageBinItems.binItem;
    const isAlreadyInspected = sublineItems.some(
      (sublineItem) => sublineItem.serialNumber === serialNumber
    );

    const uninspectedSublineItem = sublineItems.find(
      (sublineItem) => sublineItem.serialNumber === ''
    );

    if (!serialNumber && !binCode) {
      toast.error(
        `Serial ${serialNumber} is not valid and cannot be added to an existing sublineItem`
      );
      return;
    }
    if (isAlreadyInspected) {
      toast.error(`Serial ${serialNumber} has already been inspected`);
      return;
    }
    if (uninspectedSublineItem === undefined) {
      toast.error(
        `All serial numbers for this item have already been inspected!`
      );
      return;
    }
    try {
      dispatch(setLoading(true));
      const endpoint = `/subline-items/serial-number`;
      const { sublineItemId } = uninspectedSublineItem;
      const res = await imsAPI.put(endpoint, {
        binCode,
        sublineItemId,
        serialNumber,
        itemSKU: sku,
        warehouseId,
        inventoryItemId
      });
      dispatch(validateSerialSuccess(res.data));
      toast.success(`Serial ${serialNumber} has been added`, {
        autoClose: 2000
      });
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const getPalletSublineItems =
  (palletId) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/pallet/${palletId}`);
      dispatch(getPalletItemSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };

export const getBinItems = (state) => state.manageBinItems.binItem.sublineItems;
export const getPalletItems = (state) =>
  state.manageBinItems.palletItem.sublineItems;
export const getBinImage = (state) => state.manageBinItems.binItem.imageUrl;
export const getManufacturer = (state) =>
  state.manageBinItems.binItem.manufacturer;
export const serializedStatus = (state) =>
  state.manageBinItems.binItem.isSerialized;
export const selectBin = (state) => state.manageBinItems.bin;
export const selectSelectedSerialNumbers = (state) =>
  state.manageBinItems.serial;

export default manageBinItemsSlice.reducer;
