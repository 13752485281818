import React from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import infoCircleIcon from 'assets/icons/info-circle.svg';

const ScanInspectionPalletTooltip = () => (
  <Tooltip id='scan-inspection-pallet-info'>
    <p className='m-0 mb-2'>
      <img
        src={infoCircleIcon}
        alt='info-tooltip'
        width='16'
        height='16'
        key='info-tooltip'
        className='mr-1'
        style={{ marginTop: '-0.25rem' }}
      />
      Scanning a newly printed Pallet label will create a new pallet with the
      scanned label and select it.
    </p>
    <p className='mb-1 m-0'>
      <img
        src={infoCircleIcon}
        alt='info-tooltip'
        width='16'
        height='16'
        key='info-tooltip'
        className='mr-1'
        style={{ marginTop: '-0.25rem' }}
      />
      When a pallet is selected, inspecting items will also add them to the
      selected pallet.
    </p>
  </Tooltip>
);

export default ScanInspectionPalletTooltip;
