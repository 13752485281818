import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthState, useAuthProvider } from '@alpinehomeair/alpine-admin';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { useRollbarPerson } from '@rollbar/react';
import { setUser, selectUser } from '../authenticationSlice';

const AuthWait = ({ children }) => {
  const dispatch = useDispatch();
  const { authenticated, loading } = useAuthState();
  const authProvider = useAuthProvider();
  const currentUser = authProvider.getCurrentUser();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!isEqual(user, currentUser) && !loading) {
      dispatch(setUser(currentUser));
    }
  }, [dispatch, loading, user, currentUser]);

  useRollbarPerson(currentUser);

  return authenticated ? children : null;
};

AuthWait.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
};

export default AuthWait;
