import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { Button } from 'components/Elements';
import { Formik, Form, Field } from 'formik';
import palletDimensionValidator from 'utils/validation/palletDimensionValidator';
import {
  fetchShippablePalletsSpecs,
  selectPalletList
} from 'pages/Wrap/WrapSalesOrder/wrapSalesOrderSlice';

const DimensionsForm = ({ nextStep, updatePallet }) => {
  const dispatch = useDispatch();
  const palletList = useSelector(selectPalletList);

  useEffect(() => {
    dispatch(fetchShippablePalletsSpecs());
  }, [dispatch]);

  const addDimensionClickHandler = async ({ palletDimension }) => {
    const pallet = palletList.find(
      (p) => p.inventoryItemId === Number(palletDimension)
    );

    updatePallet({
      palletInventoryItemId: palletDimension,
      length: pallet.length,
      width: pallet.width
    }).then(() => {
      nextStep();
    });
  };

  return (
    <Formik
      initialValues={{ palletDimension: '' }}
      validationSchema={palletDimensionValidator}
      onSubmit={addDimensionClickHandler}
    >
      <Form autoComplete='off'>
        <h6>Length and Width</h6>
        <Field as='select' name='palletDimension' className='form-control mb-3'>
          <option value=''>Select Dimensions</option>
          {palletList.map((pallet) => (
            <option key={pallet.description} value={pallet.inventoryItemId}>
              {pallet.description}
            </option>
          ))}
        </Field>
        <Row>
          <Button color='primary' block className='w-50 m-auto' type='submit'>
            Next
          </Button>
        </Row>
      </Form>
    </Formik>
  );
};

DimensionsForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  updatePallet: PropTypes.func.isRequired
};

export default DimensionsForm;
