import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const GoToPutaway = () => {
  const history = useHistory();

  const handleClick = async () => {
    history.replace(`/receiving/bin-putaway`);
  };

  return (
    <>
      <Button color='primary' block onClick={handleClick}>
        Go to Putaway
      </Button>
    </>
  );
};

export default GoToPutaway;
