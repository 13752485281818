import * as Yup from 'yup';

export default (totalItems) =>
  Yup.object({
    cartonCount: Yup.number()
      .required('Required')
      .min(1, 'Value must be at least 1')
      .max(
        totalItems,
        'Value should not be more than the total items in the pallet'
      )
  });
