/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import getErrorMessage from 'utils/getErrorMessage';
import { setLoading } from 'components/Loader/loaderSlice';

export const inspectShipmentSlice = createSlice({
  name: 'inspectShipment',
  initialState: {
    shipments: [],
    shipment: {
      id: null,
      trackingNumber: '',
      type: '',
      typeCode: '',
      origin: '',
      inspectionProgress: 'N/A',
      inspectionStatus: 'N/A',
      items: []
    },
    shipmentItem: {
      model: '',
      manufacturer: '',
      imageUrl: '',
      inspectionProgress: 'N/A',
      inspectionStatus: 'N/A',
      isSerialized: false,
      inventoryItemId: null,
      sublineItems: []
    },
    pallet: {
      id: null,
      barcode: null,
      inventoryItemIds: []
    }
  },
  reducers: {
    getInInspectionShipmentsByLocationSuccess: (state, { payload }) => {
      state.shipments = payload;
    },
    getShipmentSuccess: (state, { payload }) => {
      state.shipment = payload;
    },
    getShipmentItemSuccess: (state, { payload }) => {
      state.shipmentItem = payload;
    },
    inspectSublineItemBySerialSuccess: (
      state,
      { payload: { sublineItems, inspectionProgress, inspectionStatus } }
    ) => {
      state.shipmentItem = {
        ...state.shipmentItem,
        inspectionProgress,
        inspectionStatus,
        sublineItems
      };
    },
    inspectInventoryItemByQtySuccess: (
      state,
      { payload: { sublineItems, inspectionProgress, inspectionStatus } }
    ) => {
      state.shipmentItem = {
        ...state.shipmentItem,
        inspectionProgress,
        inspectionStatus,
        sublineItems
      };
    },
    getSelectedPalletSuccess: (
      state,
      { payload: { id, barcode, inventoryItemIds } }
    ) => {
      state.pallet = {
        id,
        barcode,
        inventoryItemIds
      };
    },
    deselectPallet: (state) => {
      state.pallet = {
        id: null,
        barcode: null,
        inventoryItemIds: []
      };
    }
  }
});

export const {
  getInInspectionShipmentsByLocationSuccess,
  getShipmentSuccess,
  getShipmentItemSuccess,
  inspectSublineItemBySerialSuccess,
  inspectInventoryItemByQtySuccess,
  getSelectedPalletSuccess,
  deselectPallet
} = inspectShipmentSlice.actions;
export const fetchInInspectionShipmentsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/shipment/location/${locationId}/status/in_inspection`
      );
      dispatch(getInInspectionShipmentsByLocationSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const fetchShipment =
  (shipmentId) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/shipment/${shipmentId}/inspection`);
      dispatch(getShipmentSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const fetchShipmentItem =
  (shipmentId, inventoryItemId) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(
        `/shipment/${shipmentId}/inspection/${inventoryItemId}`
      );
      dispatch(getShipmentItemSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const inspectSublineItemBySerial =
  (shipmentId, inventoryItemId, serialNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    const { sublineItems, inspectionStatus } =
      getState().inspectShipment.shipmentItem;
    const isInspectedSerialAssigned = sublineItems.some(
      (sublineItem) => sublineItem.serialNumber === serialNumber
    );
    const areUnassignedSublineItemsAvailable = sublineItems.some(
      (sublineItem) => sublineItem.serialNumber === ''
    );
    const isAlreadyInspected = sublineItems.some(
      (sublineItem) =>
        sublineItem.isInspected && sublineItem.serialNumber === serialNumber
    );

    if (!isInspectedSerialAssigned && !areUnassignedSublineItemsAvailable) {
      toast.error(
        `Serial ${serialNumber} is not valid and cannot be added to an existing sublineItem`
      );
      return;
    }
    if (isAlreadyInspected) {
      toast.error(`Serial ${serialNumber} has already been inspected`);
      return;
    }
    if (inspectionStatus === 'Complete') {
      toast.error(
        `All serial numbers for this item have already been inspected`
      );
      return;
    }
    const locationId = getState().authentication.location.id;
    const selectedPalletId = getState().inspectShipment.pallet.id;
    try {
      dispatch(setLoading(true));
      const endpoint = `shipment/${shipmentId}/inspection/serial/${serialNumber}/inspect/${
        isInspectedSerialAssigned ? 'existing' : 'new'
      }`;
      const res = await imsAPI.put(endpoint, {
        locationId,
        inventoryItemId,
        selectedPalletId
      });
      dispatch(inspectSublineItemBySerialSuccess(res.data));
      toast.success(`Serial ${serialNumber} has been inspected`, {
        autoClose: 2000
      });
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const uninspectSublineItemBySerial =
  (shipmentId, inventoryItemId, serialNumber, palletId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.put(
        `shipment/${shipmentId}/inspection/serial/${serialNumber}/uninspect`,
        {
          warehouseId,
          palletId,
          inventoryItemId
        }
      );
      dispatch(inspectSublineItemBySerialSuccess(res.data));
      toast.warning(`Serial ${serialNumber} has been uninspected`, {
        autoClose: 2000
      });
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const inspectInventoryItemByQty =
  (shipmentId, inventoryItemId, quantity) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.put(
        `shipment/${shipmentId}/inspection/inventoryItem/${inventoryItemId}`,
        {
          quantity
        }
      );
      dispatch(inspectInventoryItemByQtySuccess(res.data));
      toast.success(`${quantity} quantity inspected`, {
        autoClose: 2000
      });
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const completeShipmentInspection =
  (shipmentId) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.put(
        `/shipment/${shipmentId}/inspection/complete`
      );
      dispatch(setLoading(false));
      toast.success('Item receipt created');
      return res;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const getSelectedPallet =
  (barcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const warehouseId = getState().authentication.location.id;

      dispatch(setLoading(true));

      const res = await imsAPI.get(
        `/pallet/${barcode}/inspection/${warehouseId}`
      );

      dispatch(getSelectedPalletSuccess(res.data));
      dispatch(setLoading(false));

      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));

      dispatch(setLoading(false));

      return false;
    }
  };

export const scanItemFromInspectionList =
  (shipmentId, barcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const warehouseId = getState().authentication.location.id;

      dispatch(setLoading(true));
      const res = await imsAPI.get(
        `/shipment/${shipmentId}/warehouse/${warehouseId}/item-entity/${encodeURIComponent(
          barcode
        )}`
      );

      dispatch(setLoading(false));

      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));

      dispatch(setLoading(false));

      return false;
    }
  };

export const selectShipments = (state) => state.inspectShipment.shipments;
export const selectShipment = (state) => state.inspectShipment.shipment;
export const selectShipmentItem = (state) => state.inspectShipment.shipmentItem;
export const selectSublineItems = (state) =>
  state.inspectShipment.shipmentItem.sublineItems;
export const selectInspectedSublineItems = (state) =>
  state.inspectShipment.shipmentItem.sublineItems.filter(
    (sublineItem) => sublineItem.isInspected
  );
export const selectPallet = (state) => state.inspectShipment.pallet;

export default inspectShipmentSlice.reducer;
