/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/prefer-default-export
export const formatImageUrl = (photoPath) => {
  if (!photoPath.length) {
    return 'https://images.alpinehomeair.com/photos/image-not-available.png';
  }

  const baseUrl = 'https://images.alpinehomeair.com';
  const slug = encodeURIComponent(photoPath);
  return `${baseUrl}/500x500/photos/${slug}`;
};
