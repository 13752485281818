import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from 'components/Navigation/navigationSlice';
import footerReducer from 'components/Footer/footerSlice';
import alertReducer from 'components/Alert/alertSlice';
import loaderReducer from 'components/Loader/loaderSlice';
import homeReducer from 'pages/Home/homeSlice';
import binTransferReducer from 'pages/BinTransfer/binTransferSlice';
import manageDoorReducer from 'pages/ManageDoor/manageDoorSlice';
import manageShipmentsReducer from 'pages/ManageShipments/manageShipmentsSlice';
import receivingReducer from 'pages/Receiving/ReceivingHome/receivingSlice';
import dockShipmentReducer from 'pages/Receiving/DockShipment/dockShipmentSlice';
import receiveDoorReducer from 'pages/Receiving/ReceiveDoor/receiveDoorSlice';
import inspectShipmentReducer from 'pages/Receiving/InspectShipment/inspectShipmentSlice';
import inspectBinReducer from 'pages/ManageBinItems/manageBinItemsSlice';
import binPutawayReducer from 'pages/Receiving/BinPutaway/binPutawaySlice';
import managePalletsReducer from 'pages/ManagePallets/managePalletsSlice';
import pickSalesOrderReducer from 'pages/Picking/PickSalesOrder/pickSalesOrderSlice';
import doubleCheckSalesOrderReducer from 'pages/DoubleCheck/DoubleCheckSalesOrder/doubleCheckSalesOrderSlice';
import pickLocationTransferReducer from 'pages/Picking/PickLocationTransfer/pickLocationTransferSlice';
import wrapSalesOrderReducer from 'pages/Wrap/WrapSalesOrder/wrapSalesOrderSlice';
import manageDockReducer from 'pages/ManageDock/manageDockSlice';
import revertCustomerOrderReducer from 'pages/RevertCustomerOrder/revertCustomerOrderSlice';
import itemSearchReducer from 'pages/ItemSearch/itemSearchSlice';
import shipmentDetailsReducer from 'components/ShipmentDetails/shipmentDetailsSlice';
import authenticationReducer from 'components/Authentication/authenticationSlice';

let api;
let imsAPI;
let pnetAPI;
let carrierAPI;

export const setAPI = (arg) => {
  api = arg;
};

export const setIMSAPI = (arg) => {
  imsAPI = arg;
};

export const setPNETAPI = (arg) => {
  pnetAPI = arg;
};

export const setCARRIERAPI = (arg) => {
  carrierAPI = arg;
};

export const createStore = () =>
  configureStore({
    reducer: {
      authentication: authenticationReducer,
      navigation: navigationReducer,
      alert: alertReducer,
      footer: footerReducer,
      loader: loaderReducer,
      home: homeReducer,
      binTransfer: binTransferReducer,
      manageDoor: manageDoorReducer,
      manageShipments: manageShipmentsReducer,
      receiving: receivingReducer,
      dockShipment: dockShipmentReducer,
      receiveDoor: receiveDoorReducer,
      inspectShipment: inspectShipmentReducer,
      manageBinItems: inspectBinReducer,
      shipmentDetails: shipmentDetailsReducer,
      binPutaway: binPutawayReducer,
      managePallets: managePalletsReducer,
      itemSearch: itemSearchReducer,
      pickSalesOrder: pickSalesOrderReducer,
      doubleCheckSalesOrder: doubleCheckSalesOrderReducer,
      pickLocationTransfer: pickLocationTransferReducer,
      wrapSalesOrder: wrapSalesOrderReducer,
      manageDock: manageDockReducer,
      revertCustomerOrder: revertCustomerOrderReducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { api, imsAPI, pnetAPI, carrierAPI }
        }
      })
  });
