import React, { useState } from 'react';
import cx from 'classnames';
import { Button, Row, Col, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

const PickItemDetailsFooter = ({
  isNextPick,
  isReadyToDoubleCheck,
  isReadyToFulfill,
  otherActivePickers,
  navigateToPickList,
  navigateToNextItem,
  handleCompletePick,
  handleFulfillItems
}) => {
  const [completePickOpen, setCompletePickModal] = useState(false);
  const [fulfillPickedItemsModal, setFulfillItemsModal] = useState(false);

  const completePickToggle = () => setCompletePickModal(!completePickOpen);

  const handleCompletePickClick = () => {
    if (otherActivePickers.length) {
      return completePickToggle();
    }

    return handleCompletePick();
  };

  const fulfillPickedItemsToggle = () =>
    setFulfillItemsModal(!fulfillPickedItemsModal);

  const handleFulfillItemsClick = () => {
    if (otherActivePickers.length) {
      return fulfillPickedItemsToggle();
    }

    return handleFulfillItems();
  };

  return (
    <>
      <Row className='w-100 d-flex'>
        <Col
          className={cx('pl-0 pr-0', {
            'pr-2': isNextPick || isReadyToDoubleCheck || isReadyToFulfill
          })}
        >
          <Button
            color='warning'
            block
            onClick={navigateToPickList}
            className='h-100'
          >
            Pick List
          </Button>
        </Col>
        {isNextPick && !isReadyToDoubleCheck && !isReadyToFulfill && (
          <Col className='pr-0 pl-2'>
            <Button
              color='primary'
              block
              onClick={navigateToNextItem}
              className='h-100'
            >
              Next Item
            </Button>
          </Col>
        )}
        {isReadyToDoubleCheck && !isReadyToFulfill && (
          <Col className='pr-0 pl-2'>
            <Button
              color='primary'
              block
              onClick={handleCompletePickClick}
              className='h-100'
            >
              Complete Pick
            </Button>
          </Col>
        )}
        {isReadyToFulfill && !isReadyToDoubleCheck && (
          <Col className='pr-0 pl-2'>
            <Button
              color='primary'
              block
              onClick={handleFulfillItemsClick}
              className='h-100'
            >
              Fulfill
            </Button>
          </Col>
        )}
      </Row>
      <Modal isOpen={completePickOpen} toggle={completePickToggle} centered>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>{`Are you sure you want to double-check items picked by ${otherActivePickers.join(
                ', '
              )}?`}</h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button
                color='primary'
                outline
                block
                onClick={completePickToggle}
              >
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button color='primary' block onClick={handleCompletePick}>
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={fulfillPickedItemsModal}
        toggle={fulfillPickedItemsToggle}
        centered
      >
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>{`Are you sure you want to double-check items picked by ${otherActivePickers.join(
                ', '
              )}?`}</h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button
                color='primary'
                outline
                block
                onClick={fulfillPickedItemsToggle}
              >
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button color='primary' block onClick={handleCompletePick}>
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

PickItemDetailsFooter.propTypes = {
  isNextPick: PropTypes.bool,
  isReadyToDoubleCheck: PropTypes.bool,
  isReadyToFulfill: PropTypes.bool,
  otherActivePickers: PropTypes.array,
  navigateToPickList: PropTypes.func,
  navigateToNextItem: PropTypes.func,
  handleCompletePick: PropTypes.func,
  handleFulfillItems: PropTypes.func
};

export default PickItemDetailsFooter;
