/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

export const receiveDoorSlice = createSlice({
  name: 'receiveDoor',
  initialState: {
    shipments: []
  },
  reducers: {
    getShipmentsSuccess: (state, { payload }) => {
      state.shipments = payload;
    }
  }
});

export const { getShipmentsSuccess } = receiveDoorSlice.actions;
export const fetchInDoorShipmentsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/shipment/location/${locationId}/doortype/inbound`
      );
      dispatch(getShipmentsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const fetchShipmentIDByDoor =
  (doorNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/shipment/location/${locationId}/door/${doorNumber}`
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectShipments = (state) => state.receiveDoor.shipments;

export default receiveDoorSlice.reducer;
