import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShipment,
  updateShipmentDoor,
  updateShipmentStatus,
  removeShipmentDoor
} from 'components/ShipmentDetails/shipmentDetailsSlice';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import { Modal, ModalBody, Button, FormGroup, Row, Col } from 'reactstrap';
import TextField from 'components/TextField/TextField';
import doorNumberValidator from 'utils/validation/doorNumberValidator';

const AddToDoor = () => {
  const dispatch = useDispatch();
  const shipment = useSelector(selectShipment);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const handleRemoveDoor = async () => {
    const res = await dispatch(removeShipmentDoor());
    if (res) {
      toggle();
    }
  };

  const handleSubmit = async (values) => {
    const res = await dispatch(updateShipmentDoor(values.doorNumber));
    if (res) {
      toggle();
    }
  };

  const isDoorAssigned = shipment.warehouseDoor !== 'Unassigned';

  return (
    <>
      <Row className='w-100'>
        <Col
          className={cx({
            'p-0': !isDoorAssigned,
            'pl-0 pr-2': isDoorAssigned
          })}
        >
          <Button
            color={cx({
              primary: !isDoorAssigned,
              warning: isDoorAssigned
            })}
            block
            onClick={toggle}
          >
            {!isDoorAssigned ? 'Add' : 'Edit'} Door
          </Button>
        </Col>
        {isDoorAssigned && (
          <Col className='pr-0 pl-2'>
            <Button
              color='primary'
              block
              onClick={() => dispatch(updateShipmentStatus('in_door'))}
            >
              In Door
            </Button>
          </Col>
        )}
      </Row>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Formik
            initialValues={{
              doorNumber:
                shipment.warehouseDoor !== 'Unassigned'
                  ? shipment.warehouseDoor
                  : ''
            }}
            validationSchema={doorNumberValidator}
            onSubmit={handleSubmit}
          >
            <Form autoComplete='off'>
              <FormGroup>
                <TextField
                  type='text'
                  name='doorNumber'
                  label='Door Number'
                  placeholder='Insert door number'
                />
              </FormGroup>
              <Row>
                <Col className='pr-2'>
                  <Button
                    color={isDoorAssigned ? 'warning' : 'primary'}
                    block
                    type='button'
                    onClick={isDoorAssigned ? handleRemoveDoor : toggle}
                    outline={!isDoorAssigned}
                  >
                    {isDoorAssigned ? 'Remove' : 'Cancel'}
                  </Button>
                </Col>
                <Col className='pl-2'>
                  <Button color='primary' block type='submit'>
                    Assign
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddToDoor;
