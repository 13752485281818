import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, Button, FormGroup, Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import {
  unloadShipmentToReceiving,
  selectShipment,
  removeShipmentDoor,
  updateShipmentDoor
} from 'components/ShipmentDetails/shipmentDetailsSlice';
import TextField from 'components/TextField/TextField';
import doorNumberValidator from 'utils/validation/doorNumberValidator';

const UnloadToReceiving = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const shipment = useSelector(selectShipment);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleRemoveDoor = async () => {
    const res = await dispatch(removeShipmentDoor());
    if (res) {
      toggle();
    }
  };

  const handleSubmit = async (values) => {
    const res = await dispatch(updateShipmentDoor(values.doorNumber));
    if (res) {
      toggle();
    }
  };

  const handleClick = async () => {
    const res = await dispatch(unloadShipmentToReceiving());
    if (res) {
      history.replace(`/receiving/inspect-shipment/${shipment.id}`);
    }
  };

  return (
    <>
      <Row className='w-100'>
        <Col className='pl-0 pr-2'>
          <Button className='h-100' color='warning' block onClick={toggle}>
            Edit Door
          </Button>
        </Col>
        <Col className='pr-0 pl-2'>
          <Button color='primary' block onClick={handleClick}>
            Unload to Receiving
          </Button>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Formik
            initialValues={{
              doorNumber:
                shipment.warehouseDoor !== 'Unassigned'
                  ? shipment.warehouseDoor
                  : ''
            }}
            validationSchema={doorNumberValidator}
            onSubmit={handleSubmit}
          >
            <Form autoComplete='off'>
              <FormGroup>
                <TextField
                  type='text'
                  name='doorNumber'
                  label='Door Number'
                  placeholder='Insert door number'
                />
              </FormGroup>
              <Row>
                <Col className='pr-2'>
                  <Button
                    color='warning'
                    block
                    type='button'
                    onClick={handleRemoveDoor}
                  >
                    Remove
                  </Button>
                </Col>
                <Col className='pl-2'>
                  <Button color='primary' block type='submit'>
                    Assign
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UnloadToReceiving;
