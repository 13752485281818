/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const footerSlice = createSlice({
  name: 'footer',
  initialState: {
    isActive: false,
    view: null
  },
  reducers: {
    setIsFooterActive: (state, action) => {
      state.isActive = action.payload;
    },
    setFooterView: (state, action) => {
      state.view = action.payload;
    }
  }
});

export const { setIsFooterActive, setFooterView } = footerSlice.actions;

export const selectIsFooterActive = (state) => state.footer.isActive;
export const selectFooterView = (state) => state.footer.view;

export default footerSlice.reducer;
