/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

const initialState = {
  salesOrderList: {
    orderNumber: '',
    carrierCode: '',
    palletCount: 0
  },
  salesOrder: {
    orderNumber: '',
    customerName: '',
    carrierName: '',
    carrierCode: '',
    isGuaranteedDelivery: false,
    wrapStatus: '',
    wrapProgress: '',
    canFulfillItems: false,
    unfinishedPallets: [],
    finishedPallets: []
  },
  palletList: [],
  salesOrderPallet: {
    palletId: undefined,
    palletLength: undefined,
    palletWidth: undefined,
    palletHeight: undefined,
    palletWeight: undefined,
    packWeight: undefined,
    totalItemsWeight: undefined,
    attachments: []
  }
};

export const wrapSalesOrderSlice = createSlice({
  name: 'wrapSalesOrder',
  initialState,
  reducers: {
    getSalesOrderWrapListSuccess: (state, { payload }) => {
      state.salesOrderList = payload;
    },
    getSalesOrderWrapDetailsSuccess: (state, { payload }) => {
      payload.isGuaranteedDelivery = !!payload.isGuaranteedDelivery;
      state.salesOrder = payload;
    },
    getPalletsSpecsSuccess: (state, { payload }) => {
      state.palletList = payload;
    },
    getSalesOrderWrapPalletDetailsSuccess: (state, { payload }) => {
      state.salesOrderPallet = payload;
    },
    resetSalesOrderPallet: (state) => {
      state.salesOrderPallet = initialState.salesOrderPallet;
    }
  }
});

export const {
  getSalesOrderWrapListSuccess,
  getSalesOrderWrapDetailsSuccess,
  getPalletsSpecsSuccess,
  getSalesOrderWrapPalletDetailsSuccess,
  resetSalesOrderPallet
} = wrapSalesOrderSlice.actions;

export const fetchWrapSalesOrderList =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/wrapping-sales-order/location/${locationId}/wrap-list`
      );
      dispatch(getSalesOrderWrapListSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const validateSalesOrderWrap =
  (salesOrderBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.get(
        `/wrapping-sales-order/${salesOrderBarcode}/location/${locationId}/validate-wrap`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSalesOrderWrapDetails =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/wrapping-sales-order/${salesOrderNumber}/location/${locationId}/wrap-list`
      );
      dispatch(getSalesOrderWrapDetailsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const validateSalesOrderWrapPallet =
  (palletBarcode, salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/wrapping-sales-order/${salesOrderNumber}/location/${locationId}/pallet/${palletBarcode}/validate`
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSalesOrderWrapPalletDetails =
  (salesOrderNumber, palletBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/wrapping-sales-order/${salesOrderNumber}/location/${locationId}/pallet/${palletBarcode}`
      );
      dispatch(getSalesOrderWrapPalletDetailsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchShippablePalletsSpecs =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/pallet/location/${locationId}/shippable-pallets-specs`
      );
      dispatch(getPalletsSpecsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const updateSalesOrderPallet =
  ({ salesOrderNumber, palletBarcode, payload }) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.put(
        `/wrapping-sales-order/${salesOrderNumber}/location/${locationId}/pallet/${palletBarcode}`,
        {
          payload
        }
      );
      dispatch(getSalesOrderWrapPalletDetailsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const validateManagerCredentials =
  (managerId, managerPassword) =>
  async (dispatch, getState, { pnetAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await pnetAPI.post('/api/authentication', {
        userId: managerId,
        password: managerPassword,
        roles: ['Warehouse Manager', 'Warehouse Supervisor']
      });
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const uploadPalletPhoto =
  (file, fileName, recordType, recordId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const form = new FormData();
      form.append('upload', file, fileName);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      await imsAPI.post(`/attachments/${recordType}/${recordId}`, form, config);
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const removePalletPhoto =
  (attachmentId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      await imsAPI.delete(`/attachments/${attachmentId}`);

      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const completeSalesOrderPallet =
  (salesOrderNumber, palletBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.put(
        `/wrapping-sales-order/${salesOrderNumber}/location/${locationId}/pallet/${palletBarcode}/complete`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectSalesOrderList = (state) =>
  state.wrapSalesOrder.salesOrderList;
export const selectSalesOrder = (state) => state.wrapSalesOrder.salesOrder;
export const selectSalesOrderPallet = (state) =>
  state.wrapSalesOrder.salesOrderPallet;
export const selectPalletList = (state) => state.wrapSalesOrder.palletList;
export const selectIsPalletDimensionsExists = (state) =>
  state.wrapSalesOrder.salesOrderPallet.palletLength != null &&
  state.wrapSalesOrder.salesOrderPallet.palletWidth != null &&
  state.wrapSalesOrder.salesOrderPallet.palletHeight != null;
export const selectIsPalletWeightExists = (state) =>
  state.wrapSalesOrder.salesOrderPallet.palletWeight != null;
export const selectIsAllPalletPhotosUploaded = (state) => {
  const { attachments } = state.wrapSalesOrder.salesOrderPallet;
  const sides = ['side1', 'side2', 'side3', 'side4'];
  if (!attachments || attachments.length === 0) {
    return false;
  }
  return sides.every((side) =>
    attachments.some((attachment) => attachment.side === side && attachment.url)
  );
};

export default wrapSalesOrderSlice.reducer;
