/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

export const manageDoorSlice = createSlice({
  name: 'manageDoor',
  initialState: {
    doors: [],
    door: {
      name: '',
      status: '',
      statusCode: '',
      type: '',
      carrier: '',
      shipmentStatus: ''
    }
  },
  reducers: {
    getDoorsSuccess: (state, { payload }) => {
      state.doors = payload;
    },
    getDoorSuccess: (
      state,
      {
        payload: { id, name, status, statusCode, type, carrier, shipmentStatus }
      }
    ) => {
      state.door = {
        id,
        name,
        status,
        statusCode,
        type,
        carrier,
        shipmentStatus
      };
    },
    updateDoorStatusSuccess: (state, { payload: { status, statusCode } }) => {
      state.door.status = status;
      state.door.statusCode = statusCode;
    }
  }
});

export const { getDoorsSuccess, getDoorSuccess, updateDoorStatusSuccess } =
  manageDoorSlice.actions;
export const fetchWarehouseDoorsByLocationId =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(`/door/warehouse/${warehouseId}`);
      dispatch(getDoorsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const fetchWarehouseDoorById =
  (doorId) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/door/${doorId}`);
      dispatch(getDoorSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const updateWarehouseDoorStatus =
  (statusCode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const doorId = getState().manageDoor.door.id;
      const res = await imsAPI.post(`/door/status`, {
        doorId,
        statusCode
      });
      dispatch(updateDoorStatusSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const selectWarehouseDoors = (state) => state.manageDoor.doors;
export const selectWarehouseDoor = (state) => state.manageDoor.door;

export default manageDoorSlice.reducer;
