import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, FormGroup, Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import TextField from 'components/TextField/TextField';
import Scanner from 'components/Scanner/Scanner';
import ScanCreatePalletTooltip from 'components/Tooltip/components/ScanCreatePalletTooltip';
import { createPallet } from 'pages/ManagePallets/managePalletsSlice';

const CreatePalletModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (values) => {
    const res = await dispatch(createPallet(values.palletBarcode));

    if (res) {
      history.replace(`/manage-bin-items/pallet/${res.id}`);
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody>
        <Formik
          enableReinitialize
          initialValues={{ palletBarcode: '' }}
          onSubmit={handleSubmit}
        >
          <Form autoComplete='off'>
            <FormGroup>
              <Scanner
                fieldName='palletBarcode'
                unmountToPrevious
                shouldMount={isOpen}
              >
                <TextField
                  isScannerInput
                  type='text'
                  name='palletBarcode'
                  label='Scan Pallet'
                  enabled
                  placeholder='Scan to create a pallet'
                  Tooltip={ScanCreatePalletTooltip}
                />
              </Scanner>
            </FormGroup>
          </Form>
        </Formik>
        <Row className='mb-1'>
          <Col>
            <Button color='danger' block onClick={toggle}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

CreatePalletModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.bool.isRequired
};

export default CreatePalletModal;
