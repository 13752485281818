import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody, Button, Row, Col, Badge } from 'reactstrap';
import {
  selectWarehouseDoor,
  updateWarehouseDoorStatus
} from 'pages/ManageDoor/manageDoorSlice';

const BinItemDetailsBtn = () => {
  const dispatch = useDispatch();
  const door = useSelector(selectWarehouseDoor);
  const [modal, setModal] = useState(false);
  const isDoorAvailable = door.statusCode === 'available';
  const updatedStatusCode = isDoorAvailable ? 'occupied' : 'available';
  const doorHasShipmentStatus = !!door.shipmentStatus.length;
  const toggle = () => setModal(!modal);
  const handleUpdateDoorStatus = () => {
    dispatch(updateWarehouseDoorStatus(updatedStatusCode));
  };
  return (
    <>
      <Button
        color='primary'
        block
        onClick={isDoorAvailable ? handleUpdateDoorStatus : toggle}
      >
        Mark {!isDoorAvailable && 'Available'}
        {isDoorAvailable && 'Occupied'}
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>
                This door is currently assigned
                {doorHasShipmentStatus && ' to a shipment in'}
                {doorHasShipmentStatus && (
                  <Badge color='warning mx-1'>{door.shipmentStatus}</Badge>
                )}
                {doorHasShipmentStatus && 'status'}, are you sure you want to
                mark it as available?
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button color='primary' outline block onClick={toggle}>
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button
                color='primary'
                block
                onClick={() => {
                  handleUpdateDoorStatus();
                  toggle();
                }}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BinItemDetailsBtn;
