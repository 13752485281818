import { useState, useEffect } from 'react';

function isMobile(windowWidth) {
  return windowWidth < 768;
}

function useIsMobileDevice() {
  const isWindowClient = typeof window === 'object';

  const [isMobileDevice, setIsMobileDevice] = useState(
    isWindowClient ? isMobile(window.innerWidth) : undefined
  );

  useEffect(() => {
    function setSize() {
      setIsMobileDevice(isMobile(window.innerWidth));
    }

    if (isWindowClient) {
      window.addEventListener('resize', setSize);
    }

    return () => window.removeEventListener('resize', setSize);
  }, [isWindowClient, setIsMobileDevice]);

  return isMobileDevice;
}

export default useIsMobileDevice;
