/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

const initialState = {
  inventoryItem: {},
  inventoryItemBin: [],
  sublineItem: {}
};

export const itemSearchSlice = createSlice({
  name: 'itemSearch',
  initialState,
  reducers: {
    getInventoryItemSuccess: (state, { payload }) => {
      state.inventoryItem = payload;
    },
    resetInventoryItem: (state) => {
      state.inventoryItem = initialState.inventoryItem;
    },
    getSublineItemSuccess: (state, { payload }) => {
      state.sublineItem = payload;
    },
    resetSublineItem: (state) => {
      state.sublineItem = initialState.sublineItem;
    },
    getInventoryItemBinSuccess: (state, { payload }) => {
      state.inventoryItemBin = payload;
    },
    resetInventoryItemBin: (state) => {
      state.inventoryItemBin = initialState.inventoryItemBin;
    }
  }
});

export const {
  getInventoryItemSuccess,
  resetInventoryItem,
  getSublineItemSuccess,
  resetSublineItem,
  getInventoryItemBinSuccess,
  resetInventoryItemBin
} = itemSearchSlice.actions;

export const searchInventoryItem =
  (barcode) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));

      const res = await imsAPI.post(`/inventory/item/barcode`, {
        barcode
      });

      dispatch(setLoading(false));

      return res.data;
    } catch (e) {
      dispatch(setLoading(false));

      toast.error(getErrorMessage(e));

      return false;
    }
  };

export const searchSublineItem =
  (barcode) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));

      const res = await imsAPI.post(`/subline-items/barcode`, { barcode });

      dispatch(setLoading(false));

      return res.data;
    } catch (e) {
      dispatch(setLoading(false));

      toast.error(getErrorMessage(e));

      return false;
    }
  };

export const getInventoryItem =
  (inventoryItemId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));

      const warehouseId = getState().authentication.location.id;

      const res = await imsAPI.get(
        `/inventory/item/${inventoryItemId}/warehouse/${warehouseId}/mobile`
      );

      dispatch(getInventoryItemSuccess(res.data));

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));

      toast.error(getErrorMessage(e));
    }
  };

export const getSublineItem =
  (sublineItemId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));

      const warehouseId = getState().authentication.location.id;

      const res = await imsAPI.get(
        `/subline-items/${sublineItemId}/warehouse/${warehouseId}/mobile`
      );

      dispatch(getSublineItemSuccess(res.data));

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));

      toast.error(getErrorMessage(e));
    }
  };

export const getInventoryItemBin =
  (inventoryItemId, binId) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));

      const res = await imsAPI.get(
        `/inventory/item/${inventoryItemId}/bin/${binId}/mobile`
      );

      dispatch(getInventoryItemBinSuccess(res.data));

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));

      toast.error(getErrorMessage(e));
    }
  };

export const selectInventoryItem = (state) => state.itemSearch.inventoryItem;
export const selectInventoryItemBin = (state) =>
  state.itemSearch.inventoryItemBin;
export const selectSublineItem = (state) => state.itemSearch.sublineItem;

export default itemSearchSlice.reducer;
