import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Alert
} from 'reactstrap';
import TextField from 'components/TextField/TextField';
import Scanner from 'components/Scanner/Scanner';
import { Formik, Form } from 'formik';
import {
  selectPallet,
  getSelectedPallet,
  deselectPallet,
  selectShipmentItem
} from 'pages/Receiving/InspectShipment/inspectShipmentSlice';
import cx from 'classnames';
import ScanInspectionPalletTooltip from 'components/Tooltip/components/ScanInspectionPalletTooltip';
import { setAlertColor } from '../alertSlice';

const InspectItemDetailsAlert = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(true);
  const pallet = useSelector(selectPallet);
  const item = useSelector(selectShipmentItem);

  const isPalletSelected = !!pallet.barcode;
  const doesPalletIncludeOtherSKUs =
    !!pallet.inventoryItemIds.length &&
    !pallet.inventoryItemIds.includes(item.inventoryItemId);

  useEffect(() => {
    if (isPalletSelected) {
      dispatch(setAlertColor('success'));
    } else {
      dispatch(setAlertColor('danger'));
    }
  }, [dispatch, isPalletSelected]);

  useEffect(() => {
    if (pallet.barcode) {
      toast.success(`Pallet ${pallet.barcode} selected`);
    }
  }, [pallet.barcode]);

  const toggle = () => setModal(!modal);

  const handleSubmit = async (values, { resetForm }) => {
    const start = values.palletBarcode.substring(0, 2);
    let res;
    if (start === 'KY' || start === 'NV' || start === 'PA') {
      res = await dispatch(getSelectedPallet(values.palletBarcode));
    }

    resetForm({});

    if (res) {
      const doesResPalletIncludeOtherSKUs =
        !!res.inventoryItemIds.length &&
        !res.inventoryItemIds.includes(item.inventoryItemId);

      if (!doesResPalletIncludeOtherSKUs) {
        toggle();
      }
    }
  };

  const handleDeselectPallet = () => {
    dispatch(deselectPallet());
    toggle();
  };

  return (
    <div>
      {isPalletSelected && (
        <>
          <Row>
            <Col>
              <p className='font-weight-normal m-0'>
                <span className='font-italic'>
                  Scanned items will be added to
                </span>{' '}
                <Badge
                  color={cx({
                    success: isPalletSelected,
                    danger: !isPalletSelected
                  })}
                  className='font-weight-semibold m-0'
                >
                  Pallet {pallet.barcode}
                </Badge>
              </p>
            </Col>
          </Row>
          <div
            className='text-center'
            style={{ marginTop: '-0.5rem', marginBottom: '-0.1275rem' }}
          >
            <Button color='link' className='p-0' onClick={toggle}>
              <small>Change/Edit Pallet</small>
            </Button>
          </div>
        </>
      )}
      {!isPalletSelected && (
        <>
          <Row>
            <Col>
              <p className='font-weight-normal m-0'>
                <span className='font-italic'>
                  Scanned items will not be added to pallet
                </span>
              </p>
            </Col>
          </Row>
          <div
            className='text-center'
            style={{ marginTop: '-0.5rem', marginBottom: '-0.1275rem' }}
          >
            <Button color='link' className='p-0' onClick={toggle}>
              <small>Select/Create Pallet</small>
            </Button>
          </div>
        </>
      )}
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          {doesPalletIncludeOtherSKUs && (
            <Alert color='danger'>
              WARNING: This pallet has items with a SKU that is different than
              the currently selected item.
            </Alert>
          )}
          <Formik
            enableReinitialize
            initialValues={{ palletBarcode: pallet.barcode ?? '' }}
            onSubmit={handleSubmit}
          >
            <Form autoComplete='off'>
              <FormGroup>
                <Scanner
                  fieldName='palletBarcode'
                  unmountToPrevious
                  shouldMount={modal}
                >
                  <TextField
                    isScannerInput
                    type='text'
                    name='palletBarcode'
                    label='Scan Pallet'
                    placeholder='Scan to select a pallet'
                    Tooltip={ScanInspectionPalletTooltip}
                  />
                </Scanner>
              </FormGroup>
            </Form>
          </Formik>
          <Row className='mb-1'>
            <Col>
              <Button color='danger' block onClick={handleDeselectPallet}>
                No Pallet
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InspectItemDetailsAlert;
