import decodeJwt from 'jwt-decode';

const inMemoryJWTManager = () => {
  const logoutEventName = 'logout';
  const refreshEndpoint = `${process.env.REACT_APP_API_URL}/auth/refresh_token`;
  let inMemoryJWT = null;
  let isRefreshing = null;
  let refreshTimeOutId;

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const eraseToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    window.localStorage.setItem(logoutEventName, Date.now());
    return true;
  };

  const setToken = (token, delay) => {
    inMemoryJWT = token;
    // eslint-disable-next-line no-use-before-define
    refreshToken(getExpiryTimeInMinues(delay));
    return true;
  };

  const getExpiryTimeInMinues = (epoch) => {
    let diff = (new Date(epoch).getTime() - new Date().getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  const setPermissions = (permissions) => {
    localStorage.setItem('permissions', permissions);
  };

  const setCurrentUserInformation = (decodedToken) => {
    const currentUser = {
      name: decodedToken.name,
      email: decodedToken.email,
      teams: decodedToken.teams
    };
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  };

  const getRefreshToken = () => {
    isRefreshing = fetch(refreshEndpoint, {
      method: 'GET',
      credentials: 'include'
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          eraseToken();
          global.console.log('Failed to renew the jwt from the refresh token.');
          return { token: null };
        }

        if (response.status === 201) {
          return response.json();
        }

        return { jwt_token: null, jwt_token_expiry: null };
      })
      .then(({ jwt_token: token, jwt_token_expiry: tokenExpiry }) => {
        if (token) {
          const decodedToken = decodeJwt(token);
          if (typeof decodedToken.permissions !== 'undefined') {
            setPermissions(decodedToken.permissions);
          }
          if (
            typeof decodedToken.name !== 'undefined' &&
            typeof decodedToken.email !== 'undefined' &&
            typeof decodedToken.teams !== 'undefined'
          ) {
            setCurrentUserInformation(decodedToken);
          }
          setToken(token, tokenExpiry);
          return true;
        }
        eraseToken();
        return false;
      });

    return isRefreshing;
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return getRefreshToken();
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  const refreshToken = (delay) => {
    refreshTimeOutId = window.setTimeout(getRefreshToken, delay * 10000 - 5000);
  };

  const getToken = () => inMemoryJWT;

  window.addEventListener('storage', (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  return {
    eraseToken,
    getToken,
    getRefreshToken,
    waitForTokenRefresh
  };
};

export default inMemoryJWTManager();
