import React from 'react';
import PropTypes from 'prop-types';
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import infoCircleIcon from 'assets/icons/info-circle.svg';

const Tooltip = ({ id, children }) => (
  <div className='d-flex'>
    <Button
      id={id}
      color='link'
      className='m-0 ml-1 p-0 d-flex align-items-center'
      type='button'
    >
      <img
        src={infoCircleIcon}
        alt='info-tooltip'
        width='16'
        height='16'
        key='info-tooltip'
      />
    </Button>
    <UncontrolledPopover placement='bottom' target={id} trigger='legacy'>
      <PopoverBody className='text-light font-italic'>{children}</PopoverBody>
    </UncontrolledPopover>
  </div>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Tooltip;
