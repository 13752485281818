import React from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import infoCircleIcon from 'assets/icons/info-circle.svg';

const ScanCreatePalletTooltip = () => (
  <Tooltip id='scan-create-pallet-info'>
    <p className='m-0 mb-2'>
      <img
        src={infoCircleIcon}
        alt='info-tooltip'
        width='16'
        height='16'
        key='info-tooltip'
        className='mr-1'
        style={{ marginTop: '-0.25rem' }}
      />
      Scanning a newly printed Pallet label will create a new pallet with the
      scanned label.
    </p>
    <p className='mb-1 m-0'>
      <img
        src={infoCircleIcon}
        alt='info-tooltip'
        width='16'
        height='16'
        key='info-tooltip'
        className='mr-1'
        style={{ marginTop: '-0.25rem' }}
      />
      After the pallet is created, you can add items to it from Pallet
      Management
    </p>
  </Tooltip>
);

export default ScanCreatePalletTooltip;
