/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

const initialState = {
  customerOrder: {
    orderNumber: '',
    customerName: '',
    carrierName: '',
    carrierCode: '',
    isGuaranteedDelivery: false,
    status: ''
  },
  customerOrderItems: {
    assignedItems: [
      {
        palletBarcode: '',
        palletLength: null,
        palletWidth: null,
        palletHeight: null,
        palletWeight: null,
        palletCartonCount: null,
        items: [
          {
            sku: '',
            modelNumber: ''
          }
        ]
      }
    ],
    unassignedItems: [
      {
        sku: '',
        modelNumber: ''
      }
    ]
  },
  revertOptions: {
    Picked: false,
    'Double-Checked': false,
    'Wrapped/Weighted': false,
    Loaded: false
  },
  voidPO: false
};

export const revertCustomerOrderSlice = createSlice({
  name: 'revertCustomerOrder',
  initialState,
  reducers: {
    getCustomerOrderDetailsSuccess: (state, { payload }) => {
      payload.isGuaranteedDelivery = !!payload.isGuaranteedDelivery;
      state.customerOrder = payload;
    },
    getCustomerOrderItemsSuccess: (state, { payload }) => {
      state.customerOrderItems = payload;
    },
    updateRevertOptionsValues: (state, { payload }) => {
      state.revertOptions = {
        ...state.revertOptions,
        ...payload
      };
    },
    setVoidPO: (state, { payload }) => {
      state.voidPO = payload;
    }
  }
});

export const {
  getCustomerOrderDetailsSuccess,
  getCustomerOrderItemsSuccess,
  updateRevertOptionsValues,
  setVoidPO
} = revertCustomerOrderSlice.actions;

export const validateCustomerOrder =
  (salesOrderBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.get(
        `revert-customer-order/${salesOrderBarcode}/location/${locationId}/validate`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchCustomerOrderDetails =
  (salesOrderBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { data } = await imsAPI.get(
        `revert-customer-order/${salesOrderBarcode}/location/${locationId}/details`
      );
      dispatch(getCustomerOrderDetailsSuccess(data));
      dispatch(setLoading(false));
      return data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchCustomerOrderItems =
  (salesOrderBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { data } = await imsAPI.get(
        `revert-customer-order/${salesOrderBarcode}/location/${locationId}/items`
      );
      dispatch(getCustomerOrderItemsSuccess(data));
      dispatch(setLoading(false));
      return data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchCustomerOrderRevertOptions =
  (salesOrderBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { data } = await imsAPI.get(
        `revert-customer-order/${salesOrderBarcode}/location/${locationId}/options`
      );
      dispatch(updateRevertOptionsValues(data));
      dispatch(setLoading(false));
      return data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const revertCustomerOrder =
  (salesOrderBarcode, options, voidPO) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { data } = await imsAPI.post(
        `revert-customer-order/${salesOrderBarcode}/location/${locationId}/revert`,
        { options, voidPO }
      );
      dispatch(updateRevertOptionsValues(data));
      dispatch(setLoading(false));
      return data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectCustomerOrder = (state) =>
  state.revertCustomerOrder.customerOrder;
export const selectCustomerOrderItems = (state) =>
  state.revertCustomerOrder.customerOrderItems;
export const selectCustomerOrderRevertOptions = (state) =>
  state.revertCustomerOrder.revertOptions;
export const selectVoidPO = (state) => state.revertCustomerOrder.voidPO;

export default revertCustomerOrderSlice.reducer;
