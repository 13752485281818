/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import getErrorMessage from 'utils/getErrorMessage';
import { setLoading } from 'components/Loader/loaderSlice';

export const shipmentDetailsSlice = createSlice({
  name: 'shipmentDetails',
  initialState: {
    shipment: {
      id: null,
      trackingNumber: '',
      type: '',
      typeCode: '',
      carrier: '',
      origin: '',
      destination: '',
      warehouseDoor: '',
      status: '',
      statusCode: '',
      items: [],
      manufacturer: '',
      purchaseOrderId: ''
    }
  },
  reducers: {
    getShipmentSuccess: (state, { payload }) => {
      state.shipment = payload;
    },
    updateShipmentDoorSuccess: (state, { payload: { warehouseDoor } }) => {
      state.shipment = {
        ...state.shipment,
        warehouseDoor
      };
    },
    removeShipmentDoorSuccess: (
      state,
      { payload: { warehouseDoor, status, statusCode } }
    ) => {
      state.shipment = {
        ...state.shipment,
        warehouseDoor,
        status,
        statusCode
      };
    },
    updateShipmentStatusSuccess: (
      state,
      { payload: { status, statusCode } }
    ) => {
      state.shipment = {
        ...state.shipment,
        status,
        statusCode
      };
    }
  }
});

export const {
  getShipmentSuccess,
  updateShipmentDoorSuccess,
  updateShipmentStatusSuccess,
  removeShipmentDoorSuccess
} = shipmentDetailsSlice.actions;
export const fetchShipment =
  (shipmentId) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(`/shipment/${shipmentId}/client/mobile`);
      dispatch(getShipmentSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const updateShipmentDoor =
  (doorNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    const { type, trackingNumber } = getState().shipmentDetails.shipment;
    const locationId = getState().authentication.location.id;
    const shipmentId = getState().shipmentDetails.shipment.id;
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.put(`/shipment/${shipmentId}/door`, {
        locationId,
        doorNumber
      });
      dispatch(updateShipmentDoorSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `${type} ${trackingNumber} successfully assigned to door ${doorNumber}`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const removeShipmentDoor =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    const { type, trackingNumber } = getState().shipmentDetails.shipment;
    const shipmentId = getState().shipmentDetails.shipment.id;
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.post(`/shipment/${shipmentId}/remove-door`);
      dispatch(removeShipmentDoorSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(`${type} ${trackingNumber} successfully removed door`);
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const updateShipmentStatus =
  (statusCode) =>
  async (dispatch, getState, { imsAPI }) => {
    const shipmentId = getState().shipmentDetails.shipment.id;
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.put(`/shipment/${shipmentId}/status`, {
        statusCode
      });
      dispatch(updateShipmentStatusSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const unloadShipmentToReceiving =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    const { type, trackingNumber } = getState().shipmentDetails.shipment;
    const shipmentId = getState().shipmentDetails.shipment.id;
    const locationId = getState().authentication.location.id;
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.post(
        `/shipment/${shipmentId}/unloadtoreceiving`,
        {
          locationId
        }
      );
      dispatch(updateShipmentStatusSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `${type} ${trackingNumber} successfully unloaded to receiving`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectShipment = (state) => state.shipmentDetails.shipment;

export default shipmentDetailsSlice.reducer;
