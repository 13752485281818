import React from 'react';
import { useSelector } from 'react-redux';
import { selectShipment } from 'components/ShipmentDetails/shipmentDetailsSlice';
import AddToDoor from './components/AddToDoor';
import UnloadToReceiving from './components/UnloadToReceiving';
import GoToInspection from './components/GoToInspection';
import GoToPutaway from './components/GoToPutaway';

// add actions for other statuses after completing receiving/putaway flow
const getShipmentAction = (statusCode) => {
  switch (statusCode) {
    case 'in_transit':
      return <AddToDoor />;
    case 'in_door':
      return <UnloadToReceiving />;
    case 'in_inspection':
      return <GoToInspection />;
    case 'in_putaway':
      return <GoToPutaway />;
    default:
      return null;
  }
};

const ShipmentDetailsBtn = () => {
  const shipment = useSelector(selectShipment);

  if (!shipment?.status?.length) {
    return null;
  }

  return getShipmentAction(shipment.statusCode);
};

export default ShipmentDetailsBtn;
