/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    title: null,
    menuActions: []
  },
  reducers: {
    setNavigationTitle: (state, action) => {
      state.title = action.payload;
    },
    setNavigationMenuItems: (state, { payload }) => {
      state.menuActions = payload;
    }
  }
});

export const { setNavigationTitle, setNavigationMenuItems } =
  navigationSlice.actions;

export const selectNavigationTitle = (state) => state.navigation.title;
export const selectNavigationMenuActions = (state) =>
  state.navigation.menuActions;

export default navigationSlice.reducer;
