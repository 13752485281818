import React, { Suspense } from 'react';

import Layout from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import routes from 'routes';
import RenderRoutes from 'components/RenderRoutes/RenderRoutes';

function App() {
  return (
    <div>
      <Layout>
        <Suspense fallback={<Loader />}>
          <RenderRoutes routes={routes} />
        </Suspense>
      </Layout>
    </div>
  );
}

export default App;
