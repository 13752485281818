/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';
import getActivePickersFromSalesOrder from 'utils/getActivePickersFromSalesOrder';

const initialState = {
  locationTransfer: {
    canFulfillItems: null,
    locationTransferId: '',
    pickStatus: '',
    pickProgress: '',
    equipmentPickList: [],
    smallPackPickList: []
  },
  locationTransferItem: {
    locationTransferId: null,
    binName: '',
    imageUrl: '',
    inventoryItemId: null,
    isSerialized: null,
    isSmallPack: null,
    isReadyToFulfill: null,
    manufacturer: '',
    model: '',
    orderPickProgress: '',
    orderPickStatus: '',
    pickProgress: '0/0',
    pickQuantity: 0,
    pickStatus: '',
    section: '',
    sku: '',
    sublineItems: [],
    activePickers: [],
    alternativeBins: [],
    hasAlternativeBins: null
  },
  targetPickBin: {
    bin: '',
    itemQuantity: null,
    locationId: null
  },
  nextPick: {
    bin: '',
    sku: ''
  }
};

export const pickLocationTransferSlice = createSlice({
  name: 'pickLocationTransfer',
  initialState,
  reducers: {
    getLocationTransferPickDetailsSuccess: (state, { payload }) => {
      state.locationTransfer = payload;
    },
    getLocationTransferPickItemDetailsSuccess: (state, { payload }) => {
      state.locationTransferItem = payload;
    },
    getLocationTransferTargetPickBinSuccess: (state, { payload }) => {
      state.targetPickBin = payload;
    },
    getLocationTransferNextPickSuccess: (state, { payload }) => {
      state.nextPick = payload;
    },
    clearLocationTransferPickItemDetails: (state) => {
      const {
        locationTransferItem: {
          pickProgress,
          pickQuantity,
          pickStatus,
          ...rest
        }
      } = initialState;
      state.locationTransferItem = { ...state.locationTransferItem, ...rest };
    }
  }
});

export const {
  getLocationTransferPickDetailsSuccess,
  getLocationTransferPickItemDetailsSuccess,
  getLocationTransferTargetPickBinSuccess,
  getLocationTransferNextPickSuccess,
  clearLocationTransferPickItemDetails
} = pickLocationTransferSlice.actions;
export const validatePickLocationTransfer =
  (locationTransferBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      await imsAPI.get(
        `/location-transfer/${locationTransferBarcode}/warehouse/${warehouseId}/validate-pick`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchLocationTransferPickDetails =
  (locationTransferBarcode) =>
  async (dispatch, _getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await imsAPI.get(
        `/location-transfer/${locationTransferBarcode}/pick-list`
      );
      dispatch(getLocationTransferPickDetailsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchLocationTransferPickItemDetails =
  (locationTransferId, binName, itemSKU) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/location-transfer/${locationTransferId}/warehouse/${warehouseId}/pick-bin/${binName}/pick-item/${itemSKU}`
      );
      dispatch(getLocationTransferPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchLocationTransferTargetPickBinDetails =
  (binName, itemSKU) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const { pickStatus } =
        getState().pickLocationTransfer.locationTransferItem;
      if (pickStatus === 'Complete') {
        toast.error('Picking is already complete for this item');
        return false;
      }
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/bin/${binName}/warehouse/${warehouseId}/item/${itemSKU}/target-bin`
      );
      dispatch(getLocationTransferTargetPickBinSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const pickSublineItemBySerial =
  (serialNumber, binName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const { locationTransferId, inventoryItemId } =
        getState().pickLocationTransfer.locationTransferItem;
      const res = await imsAPI.post(
        `/location-transfer/${locationTransferId}/warehouse/${warehouseId}/pick-item/serialized`,
        {
          binName,
          serialNumber,
          inventoryItemId
        }
      );
      dispatch(getLocationTransferPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `Serial ${serialNumber} has been picked for location transfer #${locationTransferId}`,
        {
          autoClose: 2000
        }
      );
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const pickSublineItemByQty =
  (binName, itemSKU, quantity) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const { locationTransferId, inventoryItemId, model, manufacturer } =
        getState().pickLocationTransfer.locationTransferItem;
      const res = await imsAPI.post(
        `/location-transfer/${locationTransferId}/warehouse/${warehouseId}/pick-item/unserialized`,
        {
          binName,
          inventoryItemId,
          itemSKU,
          quantity
        }
      );
      dispatch(getLocationTransferPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `${quantity} ${manufacturer} ${model} has been picked for location transfer #${locationTransferId}`,
        {
          autoClose: 2000
        }
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const unpickSublineItem =
  (sublineItemId, binName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const { locationTransferId } =
        getState().pickLocationTransfer.locationTransferItem;
      const res = await imsAPI.post(
        `/location-transfer/${locationTransferId}/warehouse/${warehouseId}/unpick-item`,
        {
          binName,
          sublineItemId
        }
      );
      dispatch(getLocationTransferPickItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      toast.warning(
        `Item successfully unpicked for location transfer #${locationTransferId}`,
        {
          autoClose: 2000
        }
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchLocationTransferNextPick =
  (locationTransferId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/location-transfer/${locationTransferId}/warehouse/${warehouseId}/next-pick`
      );
      dispatch(getLocationTransferNextPickSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fulfillPickedItems =
  (locationTransferId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const warehouseId = getState().authentication.location.id;

      const res = await imsAPI.post(
        `/location-transfer/${locationTransferId}/warehouse/${warehouseId}/fulfill-picked-items`
      );
      if (res.data) {
        dispatch(getLocationTransferPickDetailsSuccess(res.data));
        toast.success(
          `Picked items have been fulfilled for Location Transfer #${locationTransferId}`,
          {
            autoClose: 2000
          }
        );
      }
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectLocationTransfer = (state) =>
  state.pickLocationTransfer.locationTransfer;
export const selectLocationTransferItem = (state) =>
  state.pickLocationTransfer.locationTransferItem;
export const selectSublineItems = (state) =>
  state.pickLocationTransfer.locationTransferItem.sublineItems;
export const selectPickedSublineItems = (state) =>
  state.pickLocationTransfer.locationTransferItem.sublineItems.filter(
    (sublineItem) => sublineItem.pickedBy
  );
export const selectQuantityLeftToPick = (state) =>
  state.pickLocationTransfer.locationTransferItem.sublineItems.filter(
    (sublineItem) => !sublineItem.pickedBy
  ).length;
export const selectTargetPickBin = (state) =>
  state.pickLocationTransfer.targetPickBin;
export const selectNextPick = (state) => state.pickLocationTransfer.nextPick;
export const selectLocationTransferActivePickers = (state) =>
  getActivePickersFromSalesOrder(state.pickLocationTransfer.locationTransfer);
export const selectOtherActivePickers = (state) =>
  state.pickLocationTransfer.locationTransferItem.activePickers.filter(
    (name) => name !== state.authentication.user.name
  );

export default pickLocationTransferSlice.reducer;
