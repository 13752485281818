import React from 'react';
import RenderRoutes from 'components/RenderRoutes/RenderRoutes';

const Home = React.lazy(() => import('pages/Home/Home'));
const Login = React.lazy(() => import('pages/Login'));
const ChangeLocation = React.lazy(() =>
  import('pages/ChangeLocation/ChangeLocation')
);
const Picking = React.lazy(() =>
  import('pages/Picking/PickingHome/PickingHome')
);
const PickSalesOrder = React.lazy(() =>
  import('pages/Picking/PickSalesOrder/PickSalesOrderHome/PickSalesOrderHome')
);
const PickSalesOrderDetails = React.lazy(() =>
  import(
    'pages/Picking/PickSalesOrder/PickSalesOrderDetails/PickSalesOrderDetails'
  )
);
const RedirectPickSalesOrder = React.lazy(() =>
  import(
    'pages/Picking/PickSalesOrder/RedirectPickSalesOrder/RedirectPickSalesOrder'
  )
);
const PickSalesOrderItemDetails = React.lazy(() =>
  import(
    'pages/Picking/PickSalesOrder/PickSalesOrderItemDetails/PickSalesOrderItemDetails'
  )
);
const PickLocationTransfer = React.lazy(() =>
  import(
    'pages/Picking/PickLocationTransfer/PickLocationTransferHome/PickLocationTransferHome'
  )
);
const PickLocationTransferDetails = React.lazy(() =>
  import(
    'pages/Picking/PickLocationTransfer/PickLocationTransferDetails/PickLocationTransferDetails'
  )
);
const RedirectPickLocationTransfer = React.lazy(() =>
  import(
    'pages/Picking/PickLocationTransfer/RedirectPickLocationTransfer/RedirectPickLocationTransfer'
  )
);
const PickLocationTransferItemDetails = React.lazy(() =>
  import(
    'pages/Picking/PickLocationTransfer/PickLocationTransferItemDetails/PickLocationTransferItemDetails'
  )
);
const DoubleCheckSalesOrder = React.lazy(() =>
  import(
    'pages/DoubleCheck/DoubleCheckSalesOrder/DoubleCheckSalesOrderHome/DoubleCheckSalesOrderHome'
  )
);
const DoubleCheckSalesOrderDetails = React.lazy(() =>
  import(
    'pages/DoubleCheck/DoubleCheckSalesOrder/DoubleCheckSalesOrderDetails/DoubleCheckSalesOrderDetails'
  )
);
const DoubleCheckSalesOrderPalletDetails = React.lazy(() =>
  import(
    'pages/DoubleCheck/DoubleCheckSalesOrder/DoubleCheckSalesOrderPalletDetails/DoubleCheckSalesOrderPalletDetails'
  )
);
const RedirectDoubleCheckSalesOrder = React.lazy(() =>
  import(
    'pages/DoubleCheck/DoubleCheckSalesOrder/RedirectDoubleCheckSalesOrder/RedirectDoubleCheckSalesOrder'
  )
);
const DoubleCheckSalesOrderReviewCheckList = React.lazy(() =>
  import(
    'pages/DoubleCheck/DoubleCheckSalesOrder/DoubleCheckSalesOrderReviewCheckList/DoubleCheckSalesOrderReviewCheckList'
  )
);
const WrapSalesOrder = React.lazy(() =>
  import('pages/Wrap/WrapSalesOrder/WrapSalesOrderHome/WrapSalesOrderHome')
);
const WrapSalesOrderDetails = React.lazy(() =>
  import(
    'pages/Wrap/WrapSalesOrder/WrapSalesOrderDetails/WrapSalesOrderDetails'
  )
);
const WrapSalesOrderPalletDetails = React.lazy(() =>
  import(
    'pages/Wrap/WrapSalesOrder/WrapSalesOrderPalletDetails/WrapSalesOrderPalletDetails'
  )
);
const ManageDockOutbound = React.lazy(() =>
  import('pages/ManageDock/ManageDockOutbound/ManageDockOutbound')
);
const RedirectManageDockOutbound = React.lazy(() =>
  import(
    'pages/ManageDock/RedirectManageDockOutbound/RedirectManageDockOutbound'
  )
);
const ManageDockTruckDetails = React.lazy(() =>
  import('pages/ManageDock/ManageDockTruckDetails/ManageDockTruckDetails')
);
const RedirectWrapSalesOrder = React.lazy(() =>
  import(
    'pages/Wrap/WrapSalesOrder/RedirectWrapSalesOrder/RedirectWrapSalesOrder'
  )
);
const RevertCustomerOrderHome = React.lazy(() =>
  import(
    'pages/RevertCustomerOrder/RevertCustomerOrderHome/RevertCustomerOrderHome'
  )
);
const RevertCustomerOrderDetails = React.lazy(() =>
  import(
    'pages/RevertCustomerOrder/RevertCustomerOrderDetails/RevertCustomerOrderDetails'
  )
);
const RevertCustomerOrder = React.lazy(() =>
  import('pages/RevertCustomerOrder/RevertCustomerOrder/RevertCustomerOrder')
);
const Receiving = React.lazy(() =>
  import('pages/Receiving/ReceivingHome/ReceivingHome')
);
const DockShipment = React.lazy(() =>
  import('pages/Receiving/DockShipment/DockShipmentHome/DockShipmentHome')
);
const DockShipmentDetails = React.lazy(() =>
  import('pages/Receiving/DockShipment/DockShipmentDetails/DockShipmentDetails')
);
const ReceiveDoor = React.lazy(() =>
  import('pages/Receiving/ReceiveDoor/ReceiveDoorHome/ReceiveDoorHome')
);
const ReceiveDoorDetails = React.lazy(() =>
  import('pages/Receiving/ReceiveDoor/ReceiveDoorDetails/ReceiveDoorDetails')
);
const InspectShipment = React.lazy(() =>
  import(
    'pages/Receiving/InspectShipment/InspectShipmentHome/InspectShipmentHome'
  )
);
const InspectShipmentDetails = React.lazy(() =>
  import(
    'pages/Receiving/InspectShipment/InspectShipmentDetails/InspectShipmentDetails'
  )
);
const InspectItemDetails = React.lazy(() =>
  import(
    'pages/Receiving/InspectShipment/InspectItemDetails/InspectItemDetails'
  )
);
const BinPutaway = React.lazy(() =>
  import('pages/Receiving/BinPutaway/BinPutawayHome/BinPutawayHome')
);
const BinPutawayItemDetails = React.lazy(() =>
  import(
    'pages/Receiving/BinPutaway/BinPutawayItemDetails/BinPutawayItemDetails'
  )
);
const BinPutawayConfirmation = React.lazy(() =>
  import(
    'pages/Receiving/BinPutaway/BinPutawayConfirmation/BinPutawayConfirmation'
  )
);
const RedirectBinPutawayEntity = React.lazy(() =>
  import(
    'pages/Receiving/BinPutaway/RedirectBinPutawayEntity/RedirectBinPutawayEntity'
  )
);
const RedirectBinPutawayPallet = React.lazy(() =>
  import(
    'pages/Receiving/BinPutaway/RedirectBinPutawayPallet/RedirectBinPutawayPallet'
  )
);
const BinTransfer = React.lazy(() =>
  import('pages/BinTransfer/BinTransferHome/BinTransferHome')
);
const BinTransferBinDetails = React.lazy(() =>
  import('pages/BinTransfer/BinTransferBinDetails/BinTransferBinDetails')
);
const BinTransferItemDetails = React.lazy(() =>
  import('pages/BinTransfer/BinTransferItemDetails/BinTransferItemDetails')
);
const RedirectBinTransferPallet = React.lazy(() =>
  import(
    'pages/BinTransfer/RedirectBinTransferPallet/RedirectBinTransferPallet'
  )
);
const BinTransferConfirmation = React.lazy(() =>
  import('pages/BinTransfer/BinTransferConfirmation/BinTransferConfirmation')
);
const RedirectBinTransferEntity = React.lazy(() =>
  import(
    'pages/BinTransfer/RedirectBinTransferEntity/RedirectBinTransferEntity'
  )
);
const ManageShipments = React.lazy(() =>
  import('pages/ManageShipments/ManageShipmentsHome/ManageShipmentsHome')
);
const ManageShipmentDetails = React.lazy(() =>
  import('pages/ManageShipments/ManageShipmentDetails/ManageShipmentDetails')
);
const ManagePallets = React.lazy(() =>
  import('pages/ManagePallets/ManagePalletsHome/ManagePalletsHome')
);
const ManagePalletsPalletDetails = React.lazy(() =>
  import(
    './pages/ManagePallets/ManagePalletsPalletDetails/ManagePalletsPalletDetails'
  )
);
const ManageDoor = React.lazy(() =>
  import('pages/ManageDoor/ManageDoorHome/ManageDoorHome')
);
const DoorDetails = React.lazy(() =>
  import('pages/ManageDoor/DoorDetails/DoorDetails')
);
const ItemSearch = React.lazy(() =>
  import('pages/ItemSearch/ItemSearchHome/ItemSearchHome')
);
const SearchInventoryItem = React.lazy(() =>
  import('pages/ItemSearch/SearchInventoryItem/SearchInventoryItem')
);
const SearchInventoryItemDetails = React.lazy(() =>
  import(
    'pages/ItemSearch/SearchInventoryItemDetails/SearchInventoryItemDetails'
  )
);
const SearchSublineItem = React.lazy(() =>
  import('pages/ItemSearch/SearchSublineItem/SearchSublineItem')
);
const SearchSublineItemDetails = React.lazy(() =>
  import('pages/ItemSearch/SearchSublineItemDetails/SearchSublineItemDetails')
);
const ManageBinItemsHome = React.lazy(() =>
  import('pages/ManageBinItems/ManageBinItemsHome/ManageBinItemsHome')
);
const ManageBinItems = React.lazy(() =>
  import('pages/ManageBinItems/BinItems/BinItems')
);
const InspectBinItems = React.lazy(() =>
  import('pages/ManageBinItems/InspectBinItems')
);
const ManageBinPalletDetails = React.lazy(() =>
  import('pages/ManageBinItems/ManageBinPalletDetails/ManageBinPalletDetails')
);

const routes = [
  {
    path: '/login',
    key: 'Login',
    component: Login,
    exact: true
  },
  {
    path: '/',
    key: 'APP',
    component: RenderRoutes,
    authenticated: true,
    routes: [
      {
        path: '/',
        key: 'ROOT',
        component: Home,
        exact: true,
        name: 'Home'
      },
      {
        path: '/change-location',
        key: 'ChangeLocation',
        component: ChangeLocation,
        exact: true
      },
      {
        path: '/picking',
        key: 'Picking',
        component: Picking,
        exact: true
      },
      {
        path: '/picking/pick-sales-order',
        key: 'PickSalesOrder',
        component: PickSalesOrder,
        exact: true
      },
      {
        path: '/picking/pick-sales-order/:salesOrderNumber',
        key: 'PickSalesOrderDetails',
        component: PickSalesOrderDetails,
        exact: true
      },
      {
        path: '/picking/pick-sales-order/:salesOrderNumber/pick-bin/:binName/item-details',
        key: 'RedirectPickSalesOrder',
        component: RedirectPickSalesOrder,
        exact: true
      },
      {
        path: '/picking/pick-sales-order/:salesOrderNumber/pick-bin/:binName/item-details/:itemSKU',
        key: 'PickSalesOrderItemDetails',
        component: PickSalesOrderItemDetails,
        exact: true
      },
      {
        path: '/picking/pick-location-transfer',
        key: 'PickLocationTransfer',
        component: PickLocationTransfer,
        exact: true
      },
      {
        path: '/picking/pick-location-transfer/:locationTransferId',
        key: 'PickLocationTransferDetails',
        component: PickLocationTransferDetails,
        exact: true
      },
      {
        path: '/picking/pick-location-transfer/:locationTransferId/pick-bin/:binName/item-details',
        key: 'RedirectPickSalesOrder',
        component: RedirectPickLocationTransfer,
        exact: true
      },
      {
        path: '/picking/pick-location-transfer/:locationTransferId/pick-bin/:binName/item-details/:itemSKU',
        key: 'PickLocationTransferItemDetails',
        component: PickLocationTransferItemDetails,
        exact: true
      },
      {
        path: '/double-check',
        key: 'DoubleCheckSalesOrder',
        component: DoubleCheckSalesOrder,
        exact: true
      },
      {
        path: '/double-check/:salesOrderNumber',
        key: 'DoubleCheckSalesOrderDetails',
        component: DoubleCheckSalesOrderDetails,
        exact: true
      },
      {
        path: '/double-check/:salesOrderNumber/pallet-details/:palletBarcode',
        key: 'DoubleCheckSalesOrderPalletDetails',
        component: DoubleCheckSalesOrderPalletDetails,
        exact: true
      },
      {
        path: '/double-check/:salesOrderNumber/pallet-details',
        key: 'RedirectDoubleCheckSalesOrder',
        component: RedirectDoubleCheckSalesOrder,
        exact: true
      },
      {
        path: '/double-check/:salesOrderNumber/double-check-bin/:binName/item-details',
        key: 'RedirectDoubleCheckSalesOrder',
        component: RedirectDoubleCheckSalesOrder,
        exact: true
      },
      {
        path: '/double-check/:salesOrderNumber/review-checklist',
        key: 'DoubleCheckSalesOrderReviewCheckList',
        component: DoubleCheckSalesOrderReviewCheckList,
        exact: true
      },
      {
        path: '/wrap',
        key: 'WrapSalesOrder',
        component: WrapSalesOrder,
        exact: true
      },
      {
        path: '/wrap/:salesOrderNumber',
        key: 'WrapSalesOrderDetails',
        component: WrapSalesOrderDetails,
        exact: true
      },
      {
        path: '/wrap/:salesOrderNumber/pallet-details/:palletBarcode',
        key: 'WrapSalesOrderPalletDetails',
        component: WrapSalesOrderPalletDetails,
        exact: true
      },
      {
        path: '/wrap/:salesOrderNumber/pallet-details',
        key: 'RedirectWrapSalesOrder',
        component: RedirectWrapSalesOrder,
        exact: true
      },
      {
        path: '/manage-dock',
        key: 'ManageDockOutbound',
        component: ManageDockOutbound,
        exact: true
      },
      {
        path: '/manage-dock/truck-details',
        key: 'RedirectManageDockOutbound',
        component: RedirectManageDockOutbound,
        exact: true
      },
      {
        path: '/manage-dock/truck-details/:truckName',
        key: 'ManageDockTruckDetails',
        component: ManageDockTruckDetails,
        exact: true
      },
      {
        path: '/revert-customer-order',
        key: 'RevertCustomerOrderHome',
        component: RevertCustomerOrderHome,
        exact: true
      },
      {
        path: '/revert-customer-order/:salesOrderNumber',
        key: 'RevertCustomerOrderDetails',
        component: RevertCustomerOrderDetails,
        exact: true
      },
      {
        path: '/revert-customer-order/:salesOrderNumber/options',
        key: 'RevertCustomerOrder',
        component: RevertCustomerOrder
      },
      {
        path: '/receiving',
        key: 'Receiving',
        component: Receiving,
        exact: true
      },
      {
        path: '/receiving/dock-shipment',
        key: 'DockShipment',
        component: DockShipment,
        exact: true
      },
      {
        path: '/receiving/dock-shipment/:shipmentId',
        key: 'DockShipmentDetails',
        component: DockShipmentDetails,
        exact: true
      },
      {
        path: '/receiving/receive-door',
        key: 'ReceiveDoor',
        component: ReceiveDoor,
        exact: true
      },
      {
        path: '/receiving/receive-door/:shipmentId',
        key: 'ReceiveDoorDetails',
        component: ReceiveDoorDetails,
        exact: true
      },
      {
        path: '/receiving/inspect-shipment',
        key: 'InspectShipment',
        component: InspectShipment,
        exact: true
      },
      {
        path: '/receiving/inspect-shipment/:shipmentId',
        key: 'InspectShipmentDetails',
        component: InspectShipmentDetails,
        exact: true
      },
      {
        path: '/receiving/inspect-shipment/:shipmentId/:inventoryItemId',
        key: 'InspectItemDetails',
        component: InspectItemDetails,
        exact: true
      },
      {
        path: '/receiving/bin-putaway',
        key: 'BinPutaway',
        component: BinPutaway,
        exact: true
      },
      {
        path: '/receiving/bin-putaway/item-details',
        key: 'RedirectBinPutawayItem',
        component: RedirectBinPutawayEntity,
        exact: true
      },
      {
        path: '/receiving/bin-putaway/item-details/:itemSKU',
        key: 'binPutawayItemDetails',
        component: BinPutawayItemDetails,
        exact: true
      },
      {
        path: '/receiving/bin-putaway/pallet-details',
        key: 'RedirectBinPutawayPallet',
        component: RedirectBinPutawayEntity,
        exact: true
      },
      {
        path: '/receiving/bin-putaway/pallet-details/:palletId',
        key: 'RedirectBinPutawayPallet',
        component: RedirectBinPutawayPallet,
        exact: true
      },
      {
        path: '/receiving/bin-putaway/item-details/:itemSKU/confirmation',
        key: 'BinPutawayItemConfirmation',
        component: BinPutawayConfirmation,
        exact: true
      },
      {
        path: '/receiving/bin-putaway/pallet-details/:palletId/confirmation',
        key: 'BinPutawayPalletConfirmation',
        component: BinPutawayConfirmation,
        exact: true
      },
      {
        path: '/bin-transfer',
        key: 'BinTransfer',
        component: BinTransfer,
        exact: true
      },
      {
        path: '/bin-transfer/:binCode',
        key: 'BinTransferBinDetails',
        component: BinTransferBinDetails,
        exact: true
      },
      {
        path: '/bin-transfer/:binCode/item-details',
        key: 'RedirectBinTransferItem',
        component: RedirectBinTransferEntity,
        exact: true
      },
      {
        path: '/bin-transfer/:binCode/item-details/:itemSKU',
        key: 'BinTransferItemDetails',
        component: BinTransferItemDetails,
        exact: true
      },
      {
        path: '/bin-transfer/:binCode/pallet-details',
        key: 'RedirectBinTransferPallet',
        component: RedirectBinTransferEntity,
        exact: true
      },
      {
        path: '/bin-transfer/:binCode/pallet-details/:palletId',
        key: 'RedirectBinTransferPalletDetails',
        component: RedirectBinTransferPallet,
        exact: true
      },
      {
        path: '/bin-transfer/:binCode/item-details/:itemSKU/confirmation',
        key: 'BinTransferItemConfirmation',
        component: BinTransferConfirmation,
        exact: true
      },
      {
        path: '/bin-transfer/:binCode/pallet-details/:palletId/confirmation',
        key: 'BinTransferPalletConfirmation',
        component: BinTransferConfirmation,
        exact: true
      },
      {
        path: '/manage-pallets',
        key: 'ManagePallets',
        component: ManagePallets,
        exact: true
      },
      {
        path: '/manage-pallets/:palletId',
        key: 'ManagePalletsPalletDetails',
        component: ManagePalletsPalletDetails,
        exact: true
      },
      {
        path: '/manage-door',
        key: 'ManageDoor',
        component: ManageDoor,
        exact: true
      },
      {
        path: '/manage-door/:doorId',
        key: 'DoorDetails',
        component: DoorDetails,
        exact: true
      },
      {
        path: '/manage-shipments',
        key: 'ManageShipments',
        component: ManageShipments,
        exact: true
      },
      {
        path: '/manage-shipments/:shipmentId',
        key: 'ManageShipmentDetails',
        component: ManageShipmentDetails,
        exact: true
      },
      {
        path: '/item-search',
        key: 'ItemSearch',
        component: ItemSearch,
        exact: true
      },
      {
        path: '/item-search/inventory-item',
        key: 'SearchInventoryItem',
        component: SearchInventoryItem,
        exact: true
      },
      {
        path: '/item-search/inventory-item/:inventoryItemId',
        key: 'SearchInventoryItemDetails',
        component: SearchInventoryItemDetails,
        exact: true
      },
      {
        path: '/item-search/subline-item',
        key: 'SearchSublineItem',
        component: SearchSublineItem,
        exact: true
      },
      {
        path: '/item-search/subline-item/:sublineItemId',
        key: 'SearchSublineItemDetails',
        component: SearchSublineItemDetails,
        exact: true
      },
      {
        path: '/manage-bin-items',
        key: 'ManageBinItemsHome',
        component: ManageBinItemsHome,
        exact: true
      },
      {
        path: '/manage-bin-items/:binCode',
        key: 'ManageBinItems',
        component: ManageBinItems,
        exact: true
      },
      {
        path: '/manage-bin-items/pallet/:palletId',
        key: 'ManageBinItemsPallet',
        component: ManageBinPalletDetails,
        exact: true
      },
      {
        path: '/manage-bin-items/:binCode/:sku',
        key: 'InspectBinItems',
        component: InspectBinItems,
        exact: true
      }
    ]
  }
];

export default routes;
