import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Navigation from 'components/Navigation/Navigation';
import Alert from 'components/Alert/Alert';
import Footer from 'components/Footer/Footer';
import useIsMobileDevice from 'utils/hooks/useIsMobileDevice';
import { selectLoading } from 'components/Loader/loaderSlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loader from 'components/Loader/Loader';

const Layout = ({ children }) => {
  const loading = useSelector(selectLoading);
  const isMobileDevice = useIsMobileDevice();

  const layoutStyle = {
    height: '100vh',
    ...(isMobileDevice && { maxHeight: '-webkit-fill-available' })
  };

  return (
    <div
      className='position-relative d-flex flex-column flex-nowrap bg-white'
      style={layoutStyle}
    >
      <Navigation />
      <Alert />
      <Container className='p-3' style={{ overflowY: 'auto', flex: 1 }}>
        {children}
      </Container>
      <Footer />
      <ToastContainer autoClose={3000} />
      {loading && <Loader />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Layout;
