import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeading = styled.h4`
  font-size: 16px;
  line-height: 12px;
  padding-bottom: 10px;
  margin-top: 30px;
`;

const FeaturedHeading = ({ title }) => {
  return <StyledHeading className='border-bottom'>{title}</StyledHeading>;
};

FeaturedHeading.propTypes = {
  title: PropTypes.string.isRequired
};

export default FeaturedHeading;
