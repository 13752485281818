import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from 'components/Elements';
import {
  removeTruck,
  unshipLoadedItems,
  selectTruck,
  selectIsReadyToDeleteTruck
} from '../../../pages/ManageDock/manageDockSlice';

const ManageDockTruckDetailsBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const truck = useSelector(selectTruck);
  const isReadyToDeleteTruck = useSelector(selectIsReadyToDeleteTruck);
  const match = useRouteMatch('/manage-dock/truck-details/:truckName');
  const handleDeleteTruck = () => {
    dispatch(removeTruck(match?.params?.truckName)).then((res) => {
      if (res) {
        history.push('/manage-dock');
      }
    });
  };

  const handleMarkUnshipped = () => {
    dispatch(unshipLoadedItems(match?.params?.truckName));
  };

  return (
    <>
      {isReadyToDeleteTruck && (
        <Button color='danger' block onClick={handleDeleteTruck}>
          Delete Truck
        </Button>
      )}
      {truck?.status === 'Shipped' && (
        <Button color='danger' block onClick={handleMarkUnshipped}>
          Mark Unshipped
        </Button>
      )}
    </>
  );
};

export default ManageDockTruckDetailsBtn;
