import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { selectShipmentItem } from 'pages/Receiving/InspectShipment/inspectShipmentSlice';

const InspectItemDetailsBtn = () => {
  const history = useHistory();
  const match = useRouteMatch(
    '/receiving/inspect-shipment/:shipmentId/:inventoryItemId'
  );
  const shipmentItem = useSelector(selectShipmentItem);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const handleClick = () => {
    if (shipmentItem.inspectionStatus !== 'Complete') {
      toggle();
    } else {
      history.replace(
        `/receiving/inspect-shipment/${match?.params?.shipmentId}`
      );
    }
  };

  return (
    <>
      <Button
        color={cx({
          warning:
            shipmentItem.inspectionStatus === 'In Progress' ||
            shipmentItem.inspectionStatus === 'Pending',
          primary: shipmentItem.inspectionStatus === 'Complete'
        })}
        block
        onClick={handleClick}
      >
        Back to Inspection
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='m-0'>
                Inspection for this item has not been completed. Are you sure
                you want to go back to shipment?
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className='pr-2'>
              <Button color='primary' outline block onClick={toggle}>
                Cancel
              </Button>
            </Col>
            <Col className='pl-2'>
              <Button
                color='primary'
                block
                onClick={() => {
                  toggle();
                  history.replace(
                    `/receiving/inspect-shipment/${match?.params?.shipmentId}`
                  );
                }}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InspectItemDetailsBtn;
