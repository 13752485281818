import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import {
  revertCustomerOrder,
  selectCustomerOrderRevertOptions,
  selectVoidPO
} from '../../../pages/RevertCustomerOrder/revertCustomerOrderSlice';

const RevertCustomerOrderBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch(
    '/revert-customer-order/:salesOrderNumber/options'
  );

  const customerOrderRevertOptions = useSelector(
    selectCustomerOrderRevertOptions
  );
  const voidPO = useSelector(selectVoidPO);

  const handleOnClick = () => {
    dispatch(
      revertCustomerOrder(
        match?.params?.salesOrderNumber,
        customerOrderRevertOptions,
        voidPO
      )
    ).then((res) => {
      if (res) {
        history.push(
          `/revert-customer-order/${match?.params?.salesOrderNumber}`
        );
      }
    });
  };

  return (
    <Button color='primary' block onClick={handleOnClick}>
      Confirm
    </Button>
  );
};

export default RevertCustomerOrderBtn;
