import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const WrapSalesOrderPalletDetailsBtn = () => {
  const history = useHistory();

  return (
    <Button
      color='primary'
      block
      onClick={() => {
        history.replace('/wrap');
      }}
    >
      Complete Order
    </Button>
  );
};

export default WrapSalesOrderPalletDetailsBtn;
