/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';
import truncateString from 'utils/truncateString';

export const manageDockSlice = createSlice({
  name: 'manageDock',
  initialState: {
    dockCarriers: [
      {
        key: '',
        carrierId: 0,
        carrierCode: '',
        palletCount: 0,
        warehouseDoorName: '',
        poPalletIds: [],
        palletOnTruckCount: 0,
        truckName: '',
        truckStatus: ''
      }
    ],
    dockCarrierShippedTrucks: [
      {
        carrierCode: '',
        truckName: '',
        truckStatus: '',
        warehouseDoorName: '',
        palletOnTruckCount: 0
      }
    ],
    occupiedDoors: [
      {
        warehouseDoor: '',
        carrierName: '',
        truckName: '',
        palletOnTruckCount: 0,
        truckStatus: ''
      }
    ],
    carriers: [
      {
        ID: 0,
        name: '',
        code: ''
      }
    ],
    truck: {
      name: '',
      carrierId: '',
      generateEBOL: false,
      warehouseDoorId: '',
      warehouseDoorName: '',
      status: '',
      signedCopyPhotographed: false,
      manifestAttachmentUrl: '',
      nonWrappedPallets: [],
      pallets: []
    },
    doors: [],
    manifest: {}
  },
  reducers: {
    getDockCarriersSuccess: (state, { payload }) => {
      state.dockCarriers = payload;
    },
    getDockCarrierShippedTrucksSuccess: (state, { payload }) => {
      state.dockCarrierShippedTrucks = payload;
    },
    getOccupiedDoorsSuccess: (state, { payload }) => {
      state.occupiedDoors = payload;
    },
    getCarriersSuccess: (state, { payload }) => {
      state.carriers = payload;
    },
    getTruckSuccess: (state, { payload }) => {
      state.truck = payload;
    },
    getDoorsSuccess: (state, { payload }) => {
      state.doors = payload;
    },
    getManifestSuccess: (state, { payload }) => {
      state.manifest = payload;
    }
  }
});

export const {
  getDockCarriersSuccess,
  getDockCarrierShippedTrucksSuccess,
  getOccupiedDoorsSuccess,
  getCarriersSuccess,
  getTruckSuccess,
  getDoorsSuccess,
  getManifestSuccess
} = manageDockSlice.actions;

export const fetchDockCarriers =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/manage-dock/location/${locationId}/dock-carriers`
      );
      dispatch(getDockCarriersSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchDockCarrierShippedTrucks =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/manage-dock/location/${locationId}/dock-carrier-shipped-trucks`
      );
      dispatch(getDockCarrierShippedTrucksSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchOccupiedDoors =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(`/manage-dock/location/${locationId}/doors`);
      dispatch(getOccupiedDoorsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchCarriers =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/manage-dock/location/${locationId}/carriers`
      );
      dispatch(getCarriersSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const createTruck =
  (carrierId, poPalletIds) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.post(
        `/manage-dock/location/${locationId}/truck`,
        {
          carrierId,
          poPalletIds
        }
      );

      dispatch(getTruckSuccess(res.data));
      dispatch(setLoading(false));

      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchTruck =
  (truckName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/manage-dock/location/${locationId}/truck/${truckName}`
      );
      dispatch(getTruckSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchDoorsByStatus =
  (statusCode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/door/warehouse/${locationId}/status/${statusCode}`
      );
      dispatch(getDoorsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const assignDoorToTruck =
  (truckName, doorId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.post(
        `/manage-dock/location/${locationId}/truck/${truckName}/door/${doorId}`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const removePalletFromTruck =
  (truckName, palletId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.delete(
        `/manage-dock/location/${locationId}/truck/${truckName}/pallet/${palletId}`
      );
      dispatch(getTruckSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const removePalletsFromTruckByPO =
  (truckName, salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.delete(
        `/manage-dock/location/${locationId}/truck/${truckName}/pallets/${salesOrderNumber}`
      );
      dispatch(getTruckSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const removeTruck =
  (truckName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.delete(
        `/manage-dock/location/${locationId}/truck/${truckName}`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const closeTruck =
  (truckName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.post(
        `/manage-dock/location/${locationId}/truck/${truckName}/close`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchManifestDetails =
  (truckName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/manage-dock/location/${locationId}/truck/${truckName}/manifest`
      );
      dispatch(setLoading(false));
      dispatch(getManifestSuccess(res.data));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const uploadManifestPdf =
  (file, fileName, recordType, recordId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const form = new FormData();
      form.append('upload', file, fileName);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      await imsAPI.post(`/attachments/${recordType}/${recordId}`, form, config);
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const uploadManifestPhoto =
  ({ file, fileName, recordId, truckName }) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;

      const form = new FormData();
      form.append('upload', file, fileName);
      form.append('recordType', 'truck');
      form.append('recordId', recordId);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      const res = await imsAPI.post(
        `/manage-dock/location/${locationId}/truck/${truckName}/manifest/upload`,
        form,
        config
      );
      dispatch(getTruckSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const generateEBOL =
  ({ salesOrderNumber }) =>
  async (dispatch, getState, { imsAPI, carrierAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/manage-dock/${salesOrderNumber}/location/${locationId}/get-ebol-details`
      );
      await carrierAPI.post(`/${res.data.carrierName}/bol/generate`, {
        salesOrderNumber,
        details: res.data
      });
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(truncateString(getErrorMessage(e), 80));
      dispatch(setLoading(false));
      return false;
    }
  };

export const printBol =
  (salesOrderNumbers) =>
  async (dispatch, getState, { pnetAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await pnetAPI.get(
        'partnernet/fulfillment/templates/printorders.cfm',
        {
          params: {
            POIDs: salesOrderNumbers.toString(),
            paperwork: 1,
            carrierCopyOnly: 1
          }
        }
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const shipLoadedItems =
  (truckName, carrierName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.post(
        `/manage-dock/location/${locationId}/truck/${truckName}/ship`
      );
      toast.success(`Fulfillment created for Carrier ${carrierName}`, {
        autoClose: 3000
      });
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const unshipLoadedItems =
  (truckName) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.post(
        `/manage-dock/location/${locationId}/truck/${truckName}/unship`
      );
      dispatch(getTruckSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectDockCarriers = (state) => {
  const { dockCarriers } = state.manageDock;
  return (
    dockCarriers?.filter((dockCarrier) => dockCarrier.carrierId !== 0) || []
  );
};
export const selectDockCarrierShippedTrucks = (state) => {
  const { dockCarrierShippedTrucks } = state.manageDock;
  return (
    dockCarrierShippedTrucks?.filter(
      (shippedTrucks) => shippedTrucks.carrierCode !== 0
    ) || []
  );
};
export const selectOccupiedDoors = (state) => state.manageDock.occupiedDoors;
export const selectCarriers = (state) => state.manageDock.carriers;
export const selectTruck = (state) => state.manageDock.truck;
export const selectPalletsToRemove = (state) => {
  const { pallets } = state.manageDock.truck;
  return (
    pallets?.filter(
      (pallet) => pallet.palletCountPerPO < pallet.totalPalletCountPerPO
    ) || []
  );
};
export const selectUniqueEBOLRecordFromPallets = (state) => {
  const { pallets } = state.manageDock.truck;
  const uniqueRecordsMap = {};
  pallets?.forEach((pallet) => {
    if (pallet.salesOrderNumber && !uniqueRecordsMap[pallet.salesOrderNumber]) {
      uniqueRecordsMap[pallet.salesOrderNumber] = {
        salesOrderNumber: pallet.salesOrderNumber,
        numericPOId: pallet.numericPOId,
        error: pallet.eBOLError
      };
    }
  });

  return Object.values(uniqueRecordsMap);
};
export const selectIsCarrierAllowedEBOL = (state) => {
  return state.manageDock.truck.generateEBOL;
};
export const selectReadyToPrintBOL = (state) => {
  const { pallets, status } = state.manageDock.truck;
  const eBOLRecords = selectUniqueEBOLRecordFromPallets(state);
  const areEBOLsGenerated = pallets?.some((pallet) => pallet.eBOLGenerated);

  return (
    eBOLRecords.length > 0 &&
    status === 'Closed In Door' &&
    selectIsCarrierAllowedEBOL(state) &&
    !areEBOLsGenerated
  );
};
export const selectReadyToPrintManifest = (state) => {
  const { pallets, status, signedCopyPhotographed } = state.manageDock.truck;

  const eBOLRecords = selectUniqueEBOLRecordFromPallets(state);

  const areEBOLsGenerated = pallets?.some((pallet) => pallet.eBOLGenerated);
  const checkIfCarrierAllowed = selectIsCarrierAllowedEBOL(state)
    ? areEBOLsGenerated
    : true;
  return (
    eBOLRecords.length > 0 &&
    status === 'Closed In Door' &&
    !signedCopyPhotographed &&
    checkIfCarrierAllowed
  );
};
export const selectIsPalletsInPartialWrap = (state) => {
  const { nonWrappedPallets } = state.manageDock.truck;
  return nonWrappedPallets?.some(
    (pallet) => pallet.status === 'Partially Wrapped'
  );
};
export const selectIsReadyToDeleteTruck = (state) => {
  const { truck } = state.manageDock;
  const isTruckStatusValid = !['Closed In Door', 'Shipped'].includes(
    truck.status
  );
  const allOrdersVoided = truck.pallets?.every(
    (pallet) => pallet.salesOrderStatus === 'Void'
  );

  return isTruckStatusValid || (allOrdersVoided && truck.status !== 'Shipped');
};
export const selectDoors = (state) => state.manageDock.doors;
export const selectManifest = (state) => state.manageDock.manifest;

export default manageDockSlice.reducer;
