/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import getErrorMessage from 'utils/getErrorMessage';
import { setLoading } from 'components/Loader/loaderSlice';

export const dockShipmentSlice = createSlice({
  name: 'dockShipment',
  initialState: {
    shipments: [],
    searchQuery: ''
  },
  reducers: {
    getInTransitShipmentsByLocationSuccess: (state, { payload }) => {
      state.shipments = payload;
    },
    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload;
    }
  }
});

export const { getInTransitShipmentsByLocationSuccess, setSearchQuery } =
  dockShipmentSlice.actions;
export const fetchInTransitShipmentsByLocation =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/shipment/location/${locationId}/status/in_transit`
      );
      dispatch(getInTransitShipmentsByLocationSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const selectSearchQuery = (state) => state.dockShipment.searchQuery;
export const selectShipments = (state) => state.dockShipment.shipments;

export default dockShipmentSlice.reducer;
