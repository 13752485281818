/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import locations from 'pages/ChangeLocation/locations';
import { setLocalStorage, getLocalStorage } from 'utils/localStorage';
import getErrorMessage from 'utils/getErrorMessage';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: {
      name: '',
      email: '',
      teams: []
    },
    location: {
      id: 0,
      name: 'Location'
    },
    isLocationRestricted: true
  },
  reducers: {
    setUser: (state, { payload: { name, email, teams } }) => {
      let location = null;
      const assignedYellowSupplyLocation = teams?.find(
        (team) => team.locationId
      );
      if (assignedYellowSupplyLocation) {
        location = assignedYellowSupplyLocation;
      } else {
        const selectedLocationId = getLocalStorage('selectedLocationId');
        // fallback to Shepherdsville for the user's location when they aren't assigned to a team with a locationId (yellow supply team)
        // and haven't selected a location in local storage
        location =
          locations.find((l) => l.locationId === selectedLocationId) ??
          locations.find((l) => l.locationId === 9);
      }
      state.user = {
        name,
        email,
        teams
      };
      state.location = { id: location.locationId, name: location.locationName };
      state.isLocationRestricted = !!assignedYellowSupplyLocation;
    },
    setLocation: (state, { payload }) => {
      setLocalStorage('selectedLocationId', payload.id);
      state.location = payload;
    }
  }
});

export const { setUser, setLocation } = authenticationSlice.actions;
export const login =
  (userID, password) =>
  async (dispatch, _getState, { pnetAPI }) => {
    try {
      dispatch(setLoading(true));
      const res = await pnetAPI.post(
        '/auth/login',
        {
          userID,
          password
        },
        { withCredentials: true }
      );
      dispatch(setLoading(false));
      if (!res.data.success) {
        throw new Error('Login failed, please check credentials and try again');
      }
      return { type: 'Success' };
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return true;
    }
  };
export const logout =
  () =>
  async (dispatch, _getState, { pnetAPI }) => {
    try {
      dispatch(setLoading(true));
      await pnetAPI.get(
        '/partnernet/security/logoff.cfm',
        {},
        { withCredentials: true }
      );
      dispatch(setLoading(false));
      return { type: 'Success' };
    } catch (e) {
      dispatch(setLoading(false));
      toast.error(getErrorMessage(e));
      return false;
    }
  };

export const selectUser = (state) => state.authentication.user;
export const selectUserFirstName = (state) =>
  state.authentication.user.name.split(' ')[0];
export const selectLocation = (state) => state.authentication.location;
export const selectIsLocationRestricted = (state) =>
  state.authentication.isLocationRestricted;

export default authenticationSlice.reducer;
