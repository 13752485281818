import React from 'react';
import { NavLink, NavbarBrand } from 'reactstrap';
import homeIcon from 'assets/icons/home.svg';

const DesktopHomeButton = () => (
  <>
    <NavLink
      className='p-0 mr-2 btn d-flex align-items-center'
      href={`${process.env.REACT_APP_PARTNERNETAPI_URL}/partnernet/ims/`}
    >
      <img
        className='mr-2'
        key={homeIcon}
        src={homeIcon}
        alt='home'
        width='24'
        height='24'
      />
    </NavLink>
    <NavbarBrand className='d-flex align-items-center p-0 mb-1'>|</NavbarBrand>
  </>
);

export default DesktopHomeButton;
