import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import {
  selectSalesOrder,
  fulfillPickedItems,
  selectSalesOrderActivePickers,
  completeSalesOrderPick
} from 'pages/Picking/PickSalesOrder/pickSalesOrderSlice';
import { selectUser } from 'components/Authentication/authenticationSlice';

const PickSalesOrderItemDetailsBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { canDoubleCheckItems, canFulfillItems, orderNumber } =
    useSelector(selectSalesOrder);
  const activePickers = useSelector(selectSalesOrderActivePickers);
  const user = useSelector(selectUser);

  const [completePickModal, setCompletePickModal] = useState(false);
  const [
    fulfillPickedSmallPackItemsModal,
    setFulfillSmallPackPickedItemsModal
  ] = useState(false);

  const toggleCompletePick = () => setCompletePickModal(!completePickModal);
  const completePickClick = () => {
    if (otherActivePickers.length) {
      return toggleCompletePick();
    }

    return handleCompletePick();
  };
  const handleCompletePick = async () => {
    dispatch(completeSalesOrderPick(orderNumber)).then(() => {
      history.replace('/picking/pick-sales-order');
    });
  };

  const toggleFulfillSmallPackPickedItems = () =>
    setFulfillSmallPackPickedItemsModal(!fulfillPickedSmallPackItemsModal);
  const fulfillSmallPackPickedItemsClick = () => {
    if (otherActivePickers.length) {
      return toggleFulfillSmallPackPickedItems();
    }

    return handleFulfillSmallPackPickedItems();
  };

  const handleFulfillSmallPackPickedItems = async () => {
    dispatch(fulfillPickedItems(orderNumber)).then((res) => {
      if (res) {
        history.replace('/picking/pick-sales-order');
      }
    });
  };

  const otherActivePickers = useMemo(
    () => activePickers.filter((picker) => picker !== user.name),
    [activePickers, user.name]
  );

  return (
    <>
      {canFulfillItems && !canDoubleCheckItems && (
        <>
          <Button
            color='primary'
            block
            onClick={fulfillSmallPackPickedItemsClick}
          >
            Fulfill Picked Items
          </Button>
          <Modal
            isOpen={fulfillPickedSmallPackItemsModal}
            toggle={toggleFulfillSmallPackPickedItems}
            centered
          >
            <ModalBody>
              <Row>
                <Col className='mb-3'>
                  <h6 className='m-0'>{`Are you sure you want to fulfill items picked by ${otherActivePickers.join(
                    ', '
                  )}?`}</h6>
                </Col>
              </Row>
              <Row>
                <Col className='pr-2'>
                  <Button
                    color='primary'
                    outline
                    block
                    onClick={toggleFulfillSmallPackPickedItems}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className='pl-2'>
                  <Button
                    color='primary'
                    block
                    onClick={handleFulfillSmallPackPickedItems}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      )}

      {canDoubleCheckItems && !canFulfillItems && (
        <>
          <Button color='primary' block onClick={completePickClick}>
            Complete Pick
          </Button>
          <Modal
            isOpen={completePickModal}
            toggle={toggleCompletePick}
            centered
          >
            <ModalBody>
              <Row>
                <Col className='mb-3'>
                  <h6 className='m-0'>{`Are you sure you want to double-check items picked by ${otherActivePickers.join(
                    ', '
                  )}?`}</h6>
                </Col>
              </Row>
              <Row>
                <Col className='pr-2'>
                  <Button
                    color='primary'
                    outline
                    block
                    onClick={toggleCompletePick}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className='pl-2'>
                  <Button color='primary' block onClick={handleCompletePick}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export default PickSalesOrderItemDetailsBtn;
