import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const RevertCustomerOrderDetailsBtn = () => {
  const history = useHistory();
  const match = useRouteMatch('/revert-customer-order/:salesOrderNumber');

  return (
    <Button
      color='primary'
      block
      onClick={() => {
        history.replace(
          `/revert-customer-order/${match?.params?.salesOrderNumber}/options`
        );
      }}
    >
      Revert Order
    </Button>
  );
};

export default RevertCustomerOrderDetailsBtn;
