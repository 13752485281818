/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setLoading } from 'components/Loader/loaderSlice';
import getErrorMessage from 'utils/getErrorMessage';

const initialState = {
  salesOrderList: {
    orderNumber: '',
    sublineItemsCount: 0,
    doubleCheckedSublineItemsCount: 0,
    orderPickCompletedTime: ''
  },
  salesOrder: {
    canFulfillItems: null,
    canWrapItems: null,
    canAddPallet: null,
    orderNumber: '',
    customerName: '',
    doubleCheckListFinalized: false,
    doubleCheckStatus: '',
    doubleCheckProgress: '',
    equipmentDoubleCheckList: [],
    smallPackDoubleCheckList: []
  },
  salesOrderItem: {
    binName: '',
    customerName: '',
    imageUrl: '',
    palletName: '',
    inventoryItemId: null,
    isSerialized: null,
    isSmallPack: null,
    isReadyToWrap: null,
    manufacturer: '',
    model: '',
    orderNumber: '',
    orderDoubleCheckProgress: '',
    orderDoubleCheckStatus: '',
    doubleCheckProgress: '0/0',
    doubleCheckQuantity: 0,
    doubleCheckStatus: '',
    section: '',
    sku: '',
    sublineItems: [
      {
        pickedBy: ''
      }
    ],
    targetBin: {
      bin: '',
      itemQuantity: null,
      warehouseId: null
    }
  },
  salesOrderPallets: {
    openPallets: [],
    closedPallets: []
  },
  salesOrderPallet: {
    palletId: null,
    palletBarcode: '',
    sublineItems: []
  },
  toBeSwappedItem: {
    binName: '',
    manufacturer: '',
    pickedBy: '',
    model: '',
    sku: '',
    serialNumber: ''
  },
  froogleList: {
    labels: [],
    rules: []
  }
};

export const doubleCheckSalesOrderSlice = createSlice({
  name: 'doubleCheckSalesOrder',
  initialState,
  reducers: {
    getSalesOrderDoubleCheckListSuccess: (state, { payload }) => {
      state.salesOrderList = payload;
    },
    getSalesOrderDoubleCheckDetailsSuccess: (state, { payload }) => {
      state.salesOrder = payload;
    },
    getSalesOrderDoubleCheckItemDetailsSuccess: (state, { payload }) => {
      state.salesOrderItem = payload;
    },
    getSalesOrderSublineItemBySerialSuccess: (state, { payload }) => {
      state.toBeSwappedItem = payload;
    },
    getSalesOrderDoubleCheckPalletSuccess: (state, { payload }) => {
      state.salesOrderPallet = payload;
    },
    getSalesOrderDoubleCheckPalletsSuccess: (state, { payload }) => {
      state.salesOrderPallets = payload;
    },
    getSalesOrderDoubleCheckFroogleListSuccess: (state, { payload }) => {
      state.froogleList = payload;
    },
    clearSalesOrderDoubleCheckItemDetails: (state) => {
      state.salesOrderItem = initialState.salesOrderItem;
    },
    clearSalesOrderDoubleCheckPalletDetails: (state) => {
      state.salesOrderPallet = initialState.salesOrderPallet;
    },
    getSalesOrderFroogleListSuccess: (state, { payload }) => {
      state.froogleList = payload;
    }
  }
});

export const {
  getSalesOrderDoubleCheckListSuccess,
  getSalesOrderDoubleCheckDetailsSuccess,
  getSalesOrderDoubleCheckItemDetailsSuccess,
  getSalesOrderSublineItemBySerialSuccess,
  getSalesOrderDoubleCheckPalletsSuccess,
  getSalesOrderDoubleCheckPalletSuccess,
  getSalesOrderDoubleCheckFroogleListSuccess,
  clearSalesOrderDoubleCheckItemDetails,
  clearSalesOrderDoubleCheckPalletDetails,
  getSalesOrderFroogleListSuccess
} = doubleCheckSalesOrderSlice.actions;

export const doubleCheckSalesOrderList =
  () =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/double-checking-sales-order/location/${locationId}/double-check-list`
      );
      dispatch(getSalesOrderDoubleCheckListSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const validateSalesOrderDoubleCheck =
  (salesOrderBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.get(
        `/double-checking-sales-order/${salesOrderBarcode}/location/${locationId}/validate-double-check`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSalesOrderDoubleCheckDetails =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/double-check-list`
      );
      dispatch(getSalesOrderDoubleCheckDetailsSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };

export const fetchSalesOrderDoubleCheckItemDetails =
  (salesOrderNumber, binName, itemSKU) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/double-check-bin/${binName}/double-check-item/${itemSKU}`
      );
      dispatch(getSalesOrderDoubleCheckItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const doubleCheckSublineItemBySerial =
  (serialNumber, binName, swapReady = false) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { orderNumber, inventoryItemId } =
        getState().doubleCheckSalesOrder.salesOrderItem;
      const res = await imsAPI.put(
        `/double-checking-sales-order/${orderNumber}/location/${locationId}/double-check-item/serialized`,
        {
          binName,
          serialNumber,
          inventoryItemId,
          swapReady
        }
      );
      dispatch(getSalesOrderDoubleCheckDetailsSuccess(res.data));
      dispatch(setLoading(false));
      if (!res.data.verifyItemToSwap) {
        toast.success(
          `Serial ${serialNumber} has been double-checked for order ${orderNumber}`
        );
      }
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const fetchSalesOrderSublineItemBySerialNumber =
  (salesOrderNumber, serialNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/get-subline-item-by-serial`,
        {
          params: {
            serialNumber
          }
        }
      );
      dispatch(getSalesOrderSublineItemBySerialSuccess(res.data));
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const doubleCheckSublineItemByQty =
  (binName, itemSKU, quantity) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const { orderNumber, inventoryItemId, model, manufacturer } =
        getState().doubleCheckSalesOrder.salesOrderItem;
      const res = await imsAPI.put(
        `/double-checking-sales-order/${orderNumber}/location/${locationId}/double-check-item/unserialized`,
        {
          binName,
          inventoryItemId,
          itemSKU,
          quantity
        }
      );
      dispatch(getSalesOrderDoubleCheckItemDetailsSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `${quantity} ${manufacturer} ${model} has been double-check for order ${orderNumber}`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const removeDoubleCheckSublineItem =
  (salesOrderNumber, sublineItemId, binName, palletId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.put(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/remove-double-check-item`,
        {
          binName,
          sublineItemId,
          palletId
        }
      );
      dispatch(getSalesOrderDoubleCheckPalletSuccess(res.data));
      dispatch(setLoading(false));
      toast.warning(
        `Item successfully removed from double-checking for order ${salesOrderNumber}`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const fetchSalesOrderItemInspectionMessage =
  (salesOrderNumber, sku) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/double-check-item/dammage-inspection-message`,
        {
          params: {
            sku
          }
        }
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const createSalesOrderDoubleCheckPallet =
  (salesOrderNumber, barcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const locationId = getState().authentication.location.id;
      dispatch(setLoading(true));
      const res = await imsAPI.post(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/pallet`,
        { barcode }
      );
      dispatch(setLoading(false));
      if (res.data !== 'PALLET_EXISTS_AND_OPEN') {
        toast.success(`Pallet ${barcode} successfully created`);
      }
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSalesOrderDoubleCheckPallet =
  (salesOrderNumber, palletBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const locationId = getState().authentication.location.id;
      dispatch(setLoading(true));
      const res = await imsAPI.get(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/pallet/${palletBarcode}`
      );
      dispatch(getSalesOrderDoubleCheckPalletSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSalesOrderDoubleCheckPallets =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const locationId = getState().authentication.location.id;
      dispatch(setLoading(true));
      const res = await imsAPI.get(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/pallets`
      );
      dispatch(getSalesOrderDoubleCheckPalletsSuccess(res.data));
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const removeItemFromSalesOrderPallet =
  ({ salesOrderNumber, palletId, palletBarcode, sublineItemId }) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const locationId = getState().authentication.location.id;
      dispatch(setLoading(true));
      const res = await imsAPI.put(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/remove-item-from-pallet`,
        {
          palletId,
          sublineItemId
        }
      );
      dispatch(getSalesOrderDoubleCheckPalletsSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `An item was successfully removed from Pallet ${palletBarcode}`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const removePalletFromSalesOrder =
  (salesOrderNumber, palletId, palletBarcode) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const locationId = getState().authentication.location.id;
      dispatch(setLoading(true));
      const res = await imsAPI.put(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/remove-pallet`,
        {
          palletId
        }
      );
      dispatch(getSalesOrderDoubleCheckPalletsSuccess(res.data));
      dispatch(setLoading(false));
      toast.success(
        `Pallet ${palletBarcode} was successfully removed from the sales order`
      );
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const updateSalesOrderPallet =
  ({ salesOrderNumber, palletBarcode, payload }) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      await imsAPI.put(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/pallet/${palletBarcode}`,
        {
          payload
        }
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const closeSalesOrderPallet =
  (salesOrderNumber, palletId) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const locationId = getState().authentication.location.id;
      dispatch(setLoading(true));
      await imsAPI.put(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/close-pallet`,
        {
          palletId
        }
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };
export const fetchFroogleList =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      dispatch(setLoading(true));
      const locationId = getState().authentication.location.id;
      const res = await imsAPI.get(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/get-froogle-list`
      );
      dispatch(getSalesOrderFroogleListSuccess(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
    }
  };
export const finalizeReviewChecklist =
  (salesOrderNumber) =>
  async (dispatch, getState, { imsAPI }) => {
    try {
      const locationId = getState().authentication.location.id;
      dispatch(setLoading(true));
      await imsAPI.put(
        `/double-checking-sales-order/${salesOrderNumber}/location/${locationId}/finalize-review-checklist`
      );
      dispatch(setLoading(false));
      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      dispatch(setLoading(false));
      return false;
    }
  };

export const selectSalesOrderList = (state) =>
  state.doubleCheckSalesOrder.salesOrderList;
export const selectSalesOrder = (state) =>
  state.doubleCheckSalesOrder.salesOrder;
export const selectSalesOrderItem = (state) =>
  state.doubleCheckSalesOrder.salesOrderItem;
export const selectAnyNotDoubleCheckedItemLeft = (state) => {
  const { salesOrder } = state.doubleCheckSalesOrder;
  return !!(
    salesOrder.equipmentDoubleCheckList.length &&
    salesOrder.smallPackDoubleCheckList.length
  );
};
export const selectQuantityLeftToDoubleCheck = (state) =>
  state.doubleCheckSalesOrder.salesOrderItem.sublineItems.filter(
    (sublineItem) => !sublineItem.doubleCheckedBy
  ).length;
export const selectSalesOrderHasSmallPackItems = (state) => {
  const { salesOrderPallets } = state.doubleCheckSalesOrder;
  const allPallets = [
    ...salesOrderPallets.closedPallets,
    ...salesOrderPallets.openPallets
  ];
  const allSublineItems = allPallets.flatMap((pallet) => pallet.sublineItems);
  return allSublineItems.some((item) => item.isSmallPack);
};
export const selectToBeSwappedItem = (state) =>
  state.doubleCheckSalesOrder.toBeSwappedItem;
export const selectSalesOrderPallet = (state) =>
  state.doubleCheckSalesOrder.salesOrderPallet;
export const selectSalesOrderPallets = (state) =>
  state.doubleCheckSalesOrder.salesOrderPallets;
export const selectCanAddPallet = (state) => {
  const { salesOrder, salesOrderPallets } = state.doubleCheckSalesOrder;
  return !!(
    (salesOrderPallets.length === 0 ||
      salesOrder.equipmentDoubleCheckList.length ||
      salesOrder.smallPackDoubleCheckList.length) &&
    !salesOrderPallets.openPallets.length
  );
};
export const selectAreAllPalletsClosed = (state) => {
  const { salesOrder, salesOrderPallets } = state.doubleCheckSalesOrder;
  return !!(
    !salesOrder.equipmentDoubleCheckList.length &&
    !salesOrder.smallPackDoubleCheckList.length &&
    !salesOrderPallets.openPallets.length &&
    salesOrderPallets.closedPallets.length
  );
};
export const selectDoubleCheckSalesOrderProgress = (state) => {
  const { salesOrder, salesOrderPallets } = state.doubleCheckSalesOrder;
  const areAllPalletsClosed = selectAreAllPalletsClosed(state);

  if (areAllPalletsClosed && salesOrder.doubleCheckListFinalized) {
    return 'Complete';
  }

  if (
    !salesOrderPallets.openPallets.length &&
    !salesOrderPallets.closedPallets.length
  ) {
    return 'Pending';
  }

  return 'In Progress';
};
export const selectAllPalletsHaveItems = (state) =>
  state.doubleCheckSalesOrder.salesOrderPallets.closedPallets.every(
    (pallet) => pallet.sublineItems.length > 0
  );
export const selectMoreThanOnePallet = (state) => {
  const { salesOrderPallets } = state.doubleCheckSalesOrder;
  const allPallets = [
    ...salesOrderPallets.closedPallets,
    ...salesOrderPallets.openPallets
  ];

  return allPallets.length > 1;
};
export const selectTotalClosedPallets = (state) => {
  return state.doubleCheckSalesOrder.salesOrderPallets.closedPallets.length;
};
export const selectTotalCartonCount = (state) => {
  return state.doubleCheckSalesOrder.salesOrderPallets.closedPallets.reduce(
    (acc, pallet) => {
      return acc + pallet.palletCartonCount;
    },
    0
  );
};
export const selectTotalDoubleCheckedItems = (state) => {
  return state.doubleCheckSalesOrder.salesOrderPallets.closedPallets.reduce(
    (acc, pallet) => {
      return acc + pallet.sublineItems.length;
    },
    0
  );
};
export const selectFroogleList = (state) =>
  state.doubleCheckSalesOrder.froogleList;

export default doubleCheckSalesOrderSlice.reducer;
