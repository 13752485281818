import inMemoryJWT from 'utils/auth/inMemoryJWTManager';

const authProvider = {
  // authentication
  login: () => Promise.resolve(),
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      inMemoryJWT.eraseToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    inMemoryJWT
      .waitForTokenRefresh()
      .then(() =>
        inMemoryJWT.getToken() ? Promise.resolve : Promise.reject()
      ),
  logout: () => {
    inMemoryJWT.eraseToken();
    localStorage.removeItem('currentUser');
    localStorage.removeItem('selectedLocationId');
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  getCurrentUser: () => {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (!user) {
      return { name: '', email: '', teams: [] };
    }
    return user;
  },
  // authorization
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  }
};

export default authProvider;
